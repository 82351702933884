
.stickyheader_init {
  z-index: 0;
  opacity: 0;
  top: -500px !important;
}

.stickyheader_on {
  z-index: 10;
  position: fixed !important;
  top: 0px !important;
  opacity: 1 !important;
  transition: top 0.4s ease-in-out;
}
