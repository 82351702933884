

      html {
        height: 100%;
        width: 100%;
      }

      body {
        font-family: 'Open Sans', serif;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        -webkit-font-smoothing: antialiased;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
      }

      

      .mjdbgModeler {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: auto;
      overflow-y: auto;
      background-color: rgba(255, 255, 255, 1);
      width: 750.00px;
    height: 980.00px;
    left: 0px;
    top: 0px;

}

.mjdbgModeler .layer1 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 695.97px;
    height: 925.60px;
    left: 27.03px;
    top: 15.75px;

}

.mjdbgModeler .layer1 .thinkSmartStudyGamesIp2022RpgPvp {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.81px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      width: 695.97px;
    height: 207.88px;
    left: 0.00px;
    top: -5.00px;

}

.mjdbgModeler .layer1 .x10981 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 670.13px;
    height: 51.14px;
    left: 22.87px;
    top: 658.75px;

}

.mjdbgModeler .layer1 .x10981 .xc1bca96c {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 64.00px;
    height: 20.00px;
    left: 199.66px;
    top: 26.14px;

}

.mjdbgModeler .layer1 .x10981 .x6b5e5d20 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 64.00px;
    height: 20.00px;
    left: 325.11px;
    top: 26.14px;

}

.mjdbgModeler .layer1 .x10981 .x02c830d8 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 64.00px;
    height: 20.00px;
    left: 580.62px;
    top: 26.14px;

}

.mjdbgModeler .layer1 .x10981 .x10980 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 670.13px;
    height: 24.20px;
    left: 0.00px;
    top: 0.00px;

}

.mjdbgModeler .layer1 .x10981 .x10980 .x10979 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 498.53px;
    height: 15.00px;
    left: 171.60px;
    top: 0.00px;

}

.mjdbgModeler .layer1 .x10981 .x10980 .x10979 .x151 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(255, 75, 161, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 501.19px;
    height: 3.19px;
    left: -1.60px;
    top: 5.88px;

}

.mjdbgModeler .layer1 .x10981 .x10980 .x10979 .x126 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(242, 33, 128, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 126.19px;
    height: 3.19px;
    left: 55.73px;
    top: 5.88px;

}

.mjdbgModeler .layer1 .x10981 .x10980 .x10979 .x150 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 213.19px;
    height: 3.19px;
    left: 245.99px;
    top: 5.91px;

}

.mjdbgModeler .layer1 .x10981 .x10980 .x10979 .x127 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(44, 128, 252, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 53.19px;
    height: 3.19px;
    left: 446.94px;
    top: 5.88px;

}

.mjdbgModeler .layer1 .x10981 .x10980 .x10979 .x239 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(255, 255, 255, 1);
      stroke: rgba(242, 33, 128, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 21.38px;
    height: 21.38px;
    left: 48.43px;
    top: -3.19px;

}

.mjdbgModeler .layer1 .x10981 .x10980 .x10979 .x240 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(255, 255, 255, 1);
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 21.38px;
    height: 21.38px;
    left: 175.39px;
    top: -3.19px;

}

.mjdbgModeler .layer1 .x10981 .x10980 .x10979 .x241 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(255, 255, 255, 1);
      stroke: rgba(44, 128, 252, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 21.38px;
    height: 21.38px;
    left: 430.39px;
    top: -3.19px;

}

.mjdbgModeler .layer1 .x10981 .x10980 .x10979 .x242 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(255, 255, 255, 1);
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 21.38px;
    height: 21.38px;
    left: 302.39px;
    top: -3.19px;

}

.mjdbgModeler .layer1 .x10981 .x10980 .x80b7ec4a {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 22.00px;
      letter-spacing: -0.80px;
      color: rgba(56, 56, 56, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 71.00px;
    height: 22.00px;
    left: 0.00px;
    top: 2.20px;

}

.mjdbgModeler .layer1 .x10981 .x74e366e0 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 64.00px;
    height: 20.00px;
    left: 452.62px;
    top: 26.14px;

}

.mjdbgModeler .layer1 .x10983 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 667.44px;
    height: 121.65px;
    left: 24.30px;
    top: 760.42px;

}

.mjdbgModeler .layer1 .x10983 .xdd10587c {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 400.00px;
    height: 110.00px;
    left: 169.00px;
    top: 6.65px;

}

.mjdbgModeler .layer1 .x10983 .x7fb219af {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 164.00px;
    height: 50.00px;
    left: 170.48px;
    top: 25.32px;

}

.mjdbgModeler .layer1 .x10983 .x4e9fcaf7 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 22.00px;
      letter-spacing: -0.80px;
      color: rgba(56, 56, 56, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 71.00px;
    height: 22.00px;
    left: 0.00px;
    top: 0.00px;

}

.mjdbgModeler .layer1 .x10983 .linesb12371e2 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 494.82px;
    height: 0.00px;
    left: 172.62px;
    top: 3.94px;

}

.mjdbgModeler .layer1 .x10983 .linesb12371e2 .x148ad7ea4ef {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(255, 75, 161, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 143.19px;
    height: 3.19px;
    left: -1.59px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .x10983 .linesb12371e2 .x1427f292329 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(242, 33, 128, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 143.19px;
    height: 3.19px;
    left: 43.41px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .x10983 .linesb12371e2 .x149403aeb67 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 173.19px;
    height: 3.19px;
    left: 165.54px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .x10983 .linesb12371e2 .x143133d20d7 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(44, 128, 252, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 173.19px;
    height: 3.19px;
    left: 303.54px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .x10983 .linesb12371e2 .x1441135c7ef {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(51, 180, 149, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 47.19px;
    height: 3.19px;
    left: 449.23px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .xHr {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(44, 128, 252, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 280.00px;
    height: 50.00px;
    left: 193.14px;
    top: 870.60px;

}

.mjdbgModeler .layer1 .xHr .xHr-0 {
    color: rgba(66, 66, 66, 1);
    font-weight: 400;
    font-style: normal;
    font-size: 18.00px;
    font-family: NanumSquare_ac, Arial;
    letter-spacing: -0.7200000000000001;
    text-decoration: none;
}

.mjdbgModeler .layer1 .xHr .xHr-8 {
    color: rgba(44, 128, 252, 1);
    font-weight: 400;
    font-style: normal;
    font-size: 18.00px;
    font-family: NanumSquare_ac, Arial;
    letter-spacing: -0.7200000000000001;
    text-decoration: none;
}

.mjdbgModeler .layer1 .x10987 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 669.35px;
    height: 168.94px;
    left: 22.39px;
    top: 320.48px;

}

.mjdbgModeler .layer1 .x10987 .x3Ex3dEx {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 374.00px;
    height: 140.00px;
    left: 178.96px;
    top: 23.94px;

}

.mjdbgModeler .layer1 .x10987 .x2905bc5e {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 22.00px;
      letter-spacing: -0.80px;
      color: rgba(56, 56, 56, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 71.00px;
    height: 22.00px;
    left: 0.00px;
    top: 0.00px;

}

.mjdbgModeler .layer1 .x10987 .lines747374e0 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 494.82px;
    height: 0.00px;
    left: 174.52px;
    top: 4.88px;

}

.mjdbgModeler .layer1 .x10987 .lines747374e0 .x148a3eebab9 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(255, 75, 161, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 143.19px;
    height: 3.19px;
    left: -1.59px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .x10987 .lines747374e0 .x14284fddc8b {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(242, 33, 128, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 143.19px;
    height: 3.19px;
    left: 43.41px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .x10987 .lines747374e0 .x149ebfb0cdc {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 173.19px;
    height: 3.19px;
    left: 165.54px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .x10987 .lines747374e0 .x143d17a313b {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(44, 128, 252, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 173.19px;
    height: 3.19px;
    left: 303.54px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .x10987 .lines747374e0 .x1446d5ea887 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(51, 180, 149, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 47.19px;
    height: 3.19px;
    left: 449.23px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .x10985 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 669.18px;
    height: 82.39px;
    left: 22.56px;
    top: 529.74px;

}

.mjdbgModeler .layer1 .x10985 .x1133e959 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 208.00px;
    height: 50.00px;
    left: 176.58px;
    top: 27.39px;

}

.mjdbgModeler .layer1 .x10985 .xd9f0e93f {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 22.00px;
      letter-spacing: -0.60px;
      color: rgba(56, 56, 56, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 71.00px;
    height: 22.00px;
    left: 0.00px;
    top: 0.00px;

}

.mjdbgModeler .layer1 .x10985 .lines42a8b9c1 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 494.82px;
    height: 0.00px;
    left: 174.36px;
    top: 7.62px;

}

.mjdbgModeler .layer1 .x10985 .lines42a8b9c1 .x14814ecbbf2 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(255, 75, 161, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 143.19px;
    height: 3.19px;
    left: -1.59px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .x10985 .lines42a8b9c1 .x142603ebd26 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(242, 33, 128, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 143.19px;
    height: 3.19px;
    left: 43.41px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .x10985 .lines42a8b9c1 .x14926bd2d7b {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 173.19px;
    height: 3.19px;
    left: 165.54px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .x10985 .lines42a8b9c1 .x143a51e297a {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(44, 128, 252, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 173.19px;
    height: 3.19px;
    left: 303.54px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .x10985 .lines42a8b9c1 .x1443f240fe7 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(51, 180, 149, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 47.19px;
    height: 3.19px;
    left: 449.23px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .x11012 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 668.56px;
    height: 49.08px;
    left: 23.18px;
    top: 228.35px;

}

.mjdbgModeler .layer1 .x11012 .xL3d {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 445.00px;
    height: 20.00px;
    left: 177.47px;
    top: 24.08px;

}

.mjdbgModeler .layer1 .x11012 .x {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 22.00px;
      letter-spacing: -0.80px;
      color: rgba(56, 56, 56, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 71.00px;
    height: 22.00px;
    left: 0.00px;
    top: 0.00px;

}

.mjdbgModeler .layer1 .x11012 .lines {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 494.82px;
    height: 0.00px;
    left: 173.74px;
    top: 8.00px;

}

.mjdbgModeler .layer1 .x11012 .lines .x148 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(255, 75, 161, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 143.19px;
    height: 3.19px;
    left: -1.59px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .x11012 .lines .x142 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(242, 33, 128, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 143.19px;
    height: 3.19px;
    left: 43.41px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .x11012 .lines .x149 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 173.19px;
    height: 3.19px;
    left: 165.54px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .x11012 .lines .x143 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(44, 128, 252, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 173.19px;
    height: 3.19px;
    left: 303.54px;
    top: -1.59px;

}

.mjdbgModeler .layer1 .x11012 .lines .x144 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(51, 180, 149, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 47.19px;
    height: 3.19px;
    left: 449.23px;
    top: -1.59px;

}

