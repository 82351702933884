

      html {
        height: 100%;
        width: 100%;
      }

      body {
        font-family: 'Open Sans', serif;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        -webkit-font-smoothing: antialiased;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
      }

      

      .mjdprogrammer {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: auto;
      overflow-y: auto;
      background-color: rgba(255, 255, 255, 1);
      width: 750.00px;
    height: 1125.00px;
    left: 0px;
    top: 0px;

}

.mjdprogrammer .layer1 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 695.97px;
    height: 1070.60px;
    left: 27.03px;
    top: 15.75px;

}

.mjdprogrammer .layer1 .thinkSmartStudyGamesIp2024Rpg {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.81px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      width: 695.97px;
    height: 207.88px;
    left: 0.00px;
    top: -5.00px;

}

.mjdprogrammer .layer1 .x10981 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 670.13px;
    height: 51.14px;
    left: 22.86px;
    top: 803.75px;

}

.mjdprogrammer .layer1 .x10981 .x9ada7683 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 64.00px;
    height: 20.00px;
    left: 199.66px;
    top: 26.14px;

}

.mjdprogrammer .layer1 .x10981 .x3001e279 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 64.00px;
    height: 20.00px;
    left: 325.10px;
    top: 26.14px;

}

.mjdprogrammer .layer1 .x10981 .x3b57913c {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 64.00px;
    height: 20.00px;
    left: 580.62px;
    top: 26.14px;

}

.mjdprogrammer .layer1 .x10981 .x10980 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 670.13px;
    height: 24.20px;
    left: 0.00px;
    top: 0.00px;

}

.mjdprogrammer .layer1 .x10981 .x10980 .x10979 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 498.53px;
    height: 15.00px;
    left: 171.60px;
    top: 0.00px;

}

.mjdprogrammer .layer1 .x10981 .x10980 .x10979 .x151 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(255, 75, 161, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 501.19px;
    height: 3.19px;
    left: -1.59px;
    top: 5.88px;

}

.mjdprogrammer .layer1 .x10981 .x10980 .x10979 .x126 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(242, 33, 128, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 126.19px;
    height: 3.19px;
    left: 55.73px;
    top: 5.88px;

}

.mjdprogrammer .layer1 .x10981 .x10980 .x10979 .x150 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 213.19px;
    height: 3.19px;
    left: 246.00px;
    top: 5.90px;

}

.mjdprogrammer .layer1 .x10981 .x10980 .x10979 .x127 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(44, 128, 252, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 53.19px;
    height: 3.19px;
    left: 446.93px;
    top: 5.88px;

}

.mjdprogrammer .layer1 .x10981 .x10980 .x10979 .x239 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(255, 255, 255, 1);
      stroke: rgba(242, 33, 128, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 21.38px;
    height: 21.38px;
    left: 48.43px;
    top: -3.19px;

}

.mjdprogrammer .layer1 .x10981 .x10980 .x10979 .x240 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(255, 255, 255, 1);
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 21.38px;
    height: 21.38px;
    left: 175.39px;
    top: -3.19px;

}

.mjdprogrammer .layer1 .x10981 .x10980 .x10979 .x241 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(255, 255, 255, 1);
      stroke: rgba(44, 128, 252, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 21.38px;
    height: 21.38px;
    left: 430.39px;
    top: -3.19px;

}

.mjdprogrammer .layer1 .x10981 .x10980 .x10979 .x242 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(255, 255, 255, 1);
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 21.38px;
    height: 21.38px;
    left: 302.39px;
    top: -3.19px;

}

.mjdprogrammer .layer1 .x10981 .x10980 .x62a5cca4 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 22.00px;
      letter-spacing: -0.80px;
      color: rgba(56, 56, 56, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 71.00px;
    height: 22.00px;
    left: 0.00px;
    top: 2.20px;

}

.mjdprogrammer .layer1 .x10981 .x981ebbc6 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 64.00px;
    height: 20.00px;
    left: 452.61px;
    top: 26.14px;

}

.mjdprogrammer .layer1 .x10983 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 667.44px;
    height: 121.65px;
    left: 24.30px;
    top: 905.42px;

}

.mjdprogrammer .layer1 .x10983 .xbcd36864 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 400.00px;
    height: 110.00px;
    left: 169.00px;
    top: 6.65px;

}

.mjdprogrammer .layer1 .x10983 .x1c4dae48 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 164.00px;
    height: 50.00px;
    left: 170.48px;
    top: 25.32px;

}

.mjdprogrammer .layer1 .x10983 .xe25a6d39 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 22.00px;
      letter-spacing: -0.80px;
      color: rgba(56, 56, 56, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 71.00px;
    height: 22.00px;
    left: 0.00px;
    top: 0.00px;

}

.mjdprogrammer .layer1 .x10983 .linesfd92252e {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 494.82px;
    height: 0.00px;
    left: 172.62px;
    top: 3.94px;

}

.mjdprogrammer .layer1 .x10983 .linesfd92252e .x1488cf290e0 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(255, 75, 161, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 143.19px;
    height: 3.19px;
    left: -1.59px;
    top: -1.60px;

}

.mjdprogrammer .layer1 .x10983 .linesfd92252e .x14207d4de51 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(242, 33, 128, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 143.19px;
    height: 3.19px;
    left: 43.41px;
    top: -1.59px;

}

.mjdprogrammer .layer1 .x10983 .linesfd92252e .x149089052b6 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 173.19px;
    height: 3.19px;
    left: 165.54px;
    top: -1.60px;

}

.mjdprogrammer .layer1 .x10983 .linesfd92252e .x14380bd625a {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(44, 128, 252, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 173.19px;
    height: 3.19px;
    left: 303.54px;
    top: -1.59px;

}

.mjdprogrammer .layer1 .x10983 .linesfd92252e .x1442a43564d {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(51, 180, 149, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 47.19px;
    height: 3.19px;
    left: 449.23px;
    top: -1.59px;

}

.mjdprogrammer .layer1 .xHr {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(44, 128, 252, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 280.00px;
    height: 50.00px;
    left: 193.14px;
    top: 1015.60px;

}

.mjdprogrammer .layer1 .xHr .xHr-0 {
    color: rgba(66, 66, 66, 1);
    font-weight: 400;
    font-style: normal;
    font-size: 18.00px;
    font-family: NanumSquare_ac, Arial;
    letter-spacing: -0.7200000000000001;
    text-decoration: none;
}

.mjdprogrammer .layer1 .xHr .xHr-8 {
    color: rgba(44, 128, 252, 1);
    font-weight: 400;
    font-style: normal;
    font-size: 18.00px;
    font-family: NanumSquare_ac, Arial;
    letter-spacing: -0.7200000000000001;
    text-decoration: none;
}

.mjdprogrammer .layer1 .x10987 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 669.35px;
    height: 198.94px;
    left: 22.39px;
    top: 327.48px;

}

.mjdprogrammer .layer1 .x10987 .c {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 374.00px;
    height: 170.00px;
    left: 178.96px;
    top: 23.94px;

}

.mjdprogrammer .layer1 .x10987 .x82e291de {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 22.00px;
      letter-spacing: -0.80px;
      color: rgba(56, 56, 56, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 71.00px;
    height: 22.00px;
    left: 0.00px;
    top: 0.00px;

}

.mjdprogrammer .layer1 .x10987 .lines3e7b98c5 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 494.82px;
    height: 0.00px;
    left: 174.53px;
    top: 4.88px;

}

.mjdprogrammer .layer1 .x10987 .lines3e7b98c5 .x148be929952 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(255, 75, 161, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 143.19px;
    height: 3.19px;
    left: -1.59px;
    top: -1.60px;

}

.mjdprogrammer .layer1 .x10987 .lines3e7b98c5 .x142648bd95c {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(242, 33, 128, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 143.19px;
    height: 3.19px;
    left: 43.41px;
    top: -1.59px;

}

.mjdprogrammer .layer1 .x10987 .lines3e7b98c5 .x149c0d5542c {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 173.19px;
    height: 3.19px;
    left: 165.54px;
    top: -1.60px;

}

.mjdprogrammer .layer1 .x10987 .lines3e7b98c5 .x143d9c44406 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(44, 128, 252, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 173.19px;
    height: 3.19px;
    left: 303.54px;
    top: -1.59px;

}

.mjdprogrammer .layer1 .x10987 .lines3e7b98c5 .x1448099b7ef {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(51, 180, 149, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 47.19px;
    height: 3.19px;
    left: 449.23px;
    top: -1.59px;

}

.mjdprogrammer .layer1 .x10985 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 669.18px;
    height: 202.39px;
    left: 22.56px;
    top: 576.74px;

}

.mjdprogrammer .layer1 .x10985 .xUnityAndroidiosDbDbAws {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 410.00px;
    height: 170.00px;
    left: 176.58px;
    top: 27.39px;

}

.mjdprogrammer .layer1 .x10985 .x76896ee7 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 22.00px;
      letter-spacing: -0.60px;
      color: rgba(56, 56, 56, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 71.00px;
    height: 22.00px;
    left: 0.00px;
    top: 0.00px;

}

.mjdprogrammer .layer1 .x10985 .lines0d26dd2a {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 494.82px;
    height: 0.00px;
    left: 174.36px;
    top: 7.62px;

}

.mjdprogrammer .layer1 .x10985 .lines0d26dd2a .x1483889f744 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(255, 75, 161, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 143.19px;
    height: 3.19px;
    left: -1.59px;
    top: -1.60px;

}

.mjdprogrammer .layer1 .x10985 .lines0d26dd2a .x1421b288dab {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(242, 33, 128, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 143.19px;
    height: 3.19px;
    left: 43.41px;
    top: -1.59px;

}

.mjdprogrammer .layer1 .x10985 .lines0d26dd2a .x149f6240439 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 173.19px;
    height: 3.19px;
    left: 165.54px;
    top: -1.60px;

}

.mjdprogrammer .layer1 .x10985 .lines0d26dd2a .x143da09ab4c {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(44, 128, 252, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 173.19px;
    height: 3.19px;
    left: 303.54px;
    top: -1.59px;

}

.mjdprogrammer .layer1 .x10985 .lines0d26dd2a .x14457a188ef {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(51, 180, 149, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 47.19px;
    height: 3.19px;
    left: 449.23px;
    top: -1.59px;

}

.mjdprogrammer .layer1 .x11012 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 668.56px;
    height: 49.08px;
    left: 23.18px;
    top: 228.35px;

}

.mjdprogrammer .layer1 .x11012 .xd7dc2d77 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 333.00px;
    height: 20.00px;
    left: 177.47px;
    top: 24.08px;

}

.mjdprogrammer .layer1 .x11012 .x {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 22.00px;
      letter-spacing: -0.80px;
      color: rgba(56, 56, 56, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 71.00px;
    height: 22.00px;
    left: 0.00px;
    top: 0.00px;

}

.mjdprogrammer .layer1 .x11012 .lines {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 494.82px;
    height: 0.00px;
    left: 173.74px;
    top: 8.00px;

}

.mjdprogrammer .layer1 .x11012 .lines .x148 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(255, 75, 161, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 143.19px;
    height: 3.19px;
    left: -1.59px;
    top: -1.60px;

}

.mjdprogrammer .layer1 .x11012 .lines .x142 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(242, 33, 128, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 143.19px;
    height: 3.19px;
    left: 43.41px;
    top: -1.59px;

}

.mjdprogrammer .layer1 .x11012 .lines .x149 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 173.19px;
    height: 3.19px;
    left: 165.54px;
    top: -1.60px;

}

.mjdprogrammer .layer1 .x11012 .lines .x143 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(44, 128, 252, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 173.19px;
    height: 3.19px;
    left: 303.54px;
    top: -1.59px;

}

.mjdprogrammer .layer1 .x11012 .lines .x144 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(51, 180, 149, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 47.19px;
    height: 3.19px;
    left: 449.23px;
    top: -1.59px;

}

