

.mrecruit .contentbody .faqlistmod {

  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -341px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 682.00px;
    height: 1835.00px;
    left: 50%;
    top: 3403.00px;

}

.mrecruit .contentbody .faqlistmod .texttitle {
  
  position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 52.00px;
      letter-spacing: -1.36px;
      color: rgba(38, 38, 38, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 34.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 518.00px;
    height: 90.00px;
    left: 0.00px;
    top: -7.00px;

}

.mrecruit .contentbody .faqlistmod .upperline {
  position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(183, 183, 183, 1);
          stroke-width: 2.00px;
          stroke-linecap: butt;
      box-shadow: none;
      width: 682.00px;
    height: 2.00px;
    left: 0.00px;
    top: 84.50px;

}

.mrecruit .contentbody .faqlistmod .faqitemmod {

  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: auto;
    height: 183.00px;
    left: 0.00px;
    top: 918.00px;
    right: 0.00px;

}

.mrecruit .contentbody .faqlistmod .faqitemmod .bottomline {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  stroke: rgba(228, 228, 228, 1);
      stroke-width: 2.00px;
      stroke-linecap: butt;
  box-shadow: none;
  width: 682.00px;
height: 2.00px;
left: 0.00px;
bottom: 0.00px;

}

.mrecruit .contentbody .faqlistmod .faqitemmod .faqtitle {

    display: table-cell;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 518.00px;
      height: auto;
      vertical-align: middle;
    top: 64.00px;
    right: 67.00px;

}

.mrecruit .contentbody .faqlistmod .faqitemmod .faqtitle .txttitle {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 52.00px;
  letter-spacing: -1.28px;
  color: rgba(38, 38, 38, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 32.00px;
  font-weight: 700;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 487.00px;
  height: auto;
left: 0.00px;
top: -7.00px;

}

.mrecruit .contentbody .faqlistmod .faqitemmod .iconq {

  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 68.00px;
    height: 68.00px;
    left: 0.00px;
    top: 49.00px;

}

.mrecruit .contentbody .faqlistmod .faqitemmod .iconq .circleicon {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  fill: rgba(67, 65, 65, 1);
  box-shadow: none;
  width: 68.00px;
height: 68.00px;
left: 0.00px;
top: 0.00px;

}

.mrecruit .contentbody .faqlistmod .faqitemmod .iconq .txtquestion {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
  line-height: 74.00px;
  letter-spacing: -1.85px;
  color: rgba(255, 255, 255, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 37.00px;
  font-weight: 700;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 26.00px;
height: 40.00px;
left: 20.00px;
top: -3.00px;

}

.mrecruit .contentbody .faqlistmod .faqitemmod .itemrect {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: transparent;
  border-bottom-color: rgba(112, 112, 112, 1);
  border-bottom-style: none;
  border-bottom-width: 1.00px;
  border-left-color: rgba(112, 112, 112, 1);
  border-left-style: none;
  border-left-width: 1.00px;
  border-right-color: rgba(112, 112, 112, 1);
  border-right-style: none;
  border-right-width: 1.00px;
  border-top-color: rgba(112, 112, 112, 1);
  border-top-style: none;
  border-top-width: 1.00px;
  border-top-left-radius: 0.00px;
  border-top-right-radius: 0.00px;
  border-bottom-left-radius: 0.00px;
  border-bottom-right-radius: 0.00px;
  box-shadow: none;
  width: auto;
height: 183.00px;
left: 0.00px;
top: 0.00px;
right: 0.00px;
}

.mrecruit .contentbody .faqlistmod .faqitemopened {

  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: auto;
    height: 783.00px;
    left: 0.00px;
    top: 135.00px;
    right: 0.00px;

}

.mrecruit .contentbody .faqlistmod .faqitemopened .bottomline {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  stroke: rgba(228, 228, 228, 1);
      stroke-width: 2.00px;
      stroke-linecap: butt;
  box-shadow: none;
  width: 682.00px;
height: 2.00px;
left: 0.00px;

}

.mrecruit .contentbody .faqlistmod .faqitemopened .faqdetail {

  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 516.00px;
    height: 88.00px;
    top: 135.45px;
    right: 67.75px;

}

.mrecruit .contentbody .faqlistmod .faqitemopened .faqdetail .textdetail {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 54.00px;
  letter-spacing: 0.00px;
  color: rgba(38, 38, 38, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 30.00px;
  font-weight: 400;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 516.00px;
  height: auto;
left: 0.00px;
top: -10.00px;

}

.mrecruit .contentbody .faqlistmod .faqitemopened .faqtitleopened {

  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 404.00px;
    height: 38.00px;
    left: 98.25px;
    top: 11.00px;

}

.mrecruit .contentbody .faqlistmod .faqitemopened .faqtitleopened .texttitle {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 52.00px;
  letter-spacing: -1.28px;
  color: rgba(100, 77, 157, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 32.00px;
  font-weight: 700;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 487.00px;
height: 35.00px;
left: -1.24px;
top: -3.00px;

}

.mrecruit .contentbody .faqlistmod .faqitemopened .iconqopened {

  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 68.00px;
    height: 68.00px;
    left: 0.00px;
    top: 0.00px;

}

.mrecruit .contentbody .faqlistmod .faqitemopened .iconqopened .circleicon {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  fill: rgba(122, 104, 245, 1);
  box-shadow: none;
  width: 68.00px;
height: 68.00px;
left: 0.00px;
top: 0.00px;

}

.mrecruit .contentbody .faqlistmod .faqitemopened .iconqopened .textq {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
  line-height: 74.00px;
  letter-spacing: -1.85px;
  color: rgba(255, 255, 255, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 37.00px;
  font-weight: 700;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 26.00px;
height: 40.00px;
left: 20.00px;
top: -3.00px;

}

.mrecruit .contentbody .faqlistmod .faqitemopened .iconaopened {

  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 68.00px;
    height: 69.00px;
    left: 0.00px;
    top: 131.00px;

}

.mrecruit .contentbody .faqlistmod .faqitemopened .iconaopened .circleicon {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  fill: rgba(229, 229, 229, 1);
  box-shadow: none;
  width: 68.00px;
height: 69.00px;
left: 0.00px;
top: 0.00px;

}

.mrecruit .contentbody .faqlistmod .faqitemopened .iconaopened .texta {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
  line-height: 78.00px;
  letter-spacing: -1.95px;
  color: rgba(122, 104, 245, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 39.00px;
  font-weight: 700;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 26.00px;
height: 43.00px;
left: 22.00px;
top: -3.50px;

}

.mrecruit .contentbody .recruitlistmod {

  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -341px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 682.00px;
    left: 50%;
    top: 1618.00px;

}

.mrecruit .contentbody .recruitlistmod .buttonapply {

  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 428.00px;
    height: 104.00px;
    left: 127.00px;
    bottom: 0.00px;

}

.mrecruit .contentbody .recruitlistmod .buttonapply .bgrect {
  position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(100, 77, 157, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 55.00px;
      border-top-right-radius: 55.00px;
      border-bottom-left-radius: 55.00px;
      border-bottom-right-radius: 55.00px;
      -webkit-filter: drop-shadow(0px 3px 6px rgba(149, 149, 149, 0.1607843137254902));
    filter: drop-shadow(0px 3px 6px rgba(149, 149, 149, 0.1607843137254902));
      width: 428.00px;
    height: 104.00px;
    left: 0.00px;
    top: 0.00px;

}

.mrecruit .contentbody .recruitlistmod .buttonapply .textapply {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: -214px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
  vertical-align: middle;
  line-height: 104px;
  letter-spacing: 0.00px;
  color: rgba(255, 255, 255, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 32.00px;
  font-weight: 700;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 428.00px;
height: 104.00px;
left: 50%;
top: 0.00px;

}

.mrecruit .contentbody .recruitlistmod .upperline {
  position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(190, 190, 190, 1);
          stroke-width: 1.00px;
          stroke-linecap: butt;
      box-shadow: none;
      width: 682.00px;
    height: 1.00px;
    left: 0.00px;
    top: -0.50px;

}

.mrecruit .contentbody .recruitlistmod .recruititem {

  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: auto;
    height: 116.00px;
    left: 0.00px;
    top: 41.00px;
    right: 0.00px;

}

.mrecruit .contentbody .recruitlistmod .recruititem .tasktitle {

  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: auto;
    height: 35.00px;
    left: 0.69px;
    top: 43.10px;

}

.mrecruit .contentbody .recruitlistmod .recruititem .tasktitle .texttitle {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: left;
  letter-spacing: -1.28px;
  color: rgba(38, 38, 38, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 32.00px;
  font-weight: 700;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: auto;
height: auto;
left: 0.00px;
top: 0.00px;

}

.mrecruit .contentbody .recruitlistmod .recruititem .jobgroup {

  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 218.00px;
    height: 26.00px;
    left: 0.00px;
    top: 0.00px;

}

.mrecruit .contentbody .recruitlistmod .recruititem .jobgroup .texttitle {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: left;
  letter-spacing: -0.92px;
  color: rgba(125, 125, 125, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 23.00px;
  font-weight: 400;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 218.00px;
height: 26.00px;
left: 0.00px;
top: 0.00px;

}

.mrecruit .contentbody .recruitlistmod .recruititem .bottomline {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  stroke: rgba(190, 190, 190, 1);
      stroke-width: 1.00px;
      stroke-linecap: butt;
  box-shadow: none;
  width: 682.00px;
height: 1.00px;
left: 0.00px;
top: 115.50px;

}

.mrecruit .contentbody .recruitlistmod .recruititem .newicon {

  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 39.00px;
    height: 39.00px;
    top: 41.00px;
    right: 141.00px;

}

.mrecruit .contentbody .recruitlistmod .recruititem .newicon .circleicon {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  fill: rgba(245, 97, 97, 1);
  box-shadow: none;
  width: 39.00px;
height: 39.00px;
left: 0.00px;
top: 0.00px;

}

.mrecruit .contentbody .recruitlistmod .recruititem .newicon .textn {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
  line-height: 36.00px;
  letter-spacing: -0.90px;
  color: rgba(255, 255, 255, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 18.00px;
  font-weight: 700;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 12.00px;
height: 20.00px;
left: 14.00px;
top: 3.00px;

}

.mrecruit .contentbody .recruitlistmod .recruitexpanded .recruititemdetailmod {

  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 681.69px;
    height: auto;
    left: 0.00px;
    top: 0.00px;

}

.mrecruit .contentbody .recruitlistmod .recruitexpanded .recruititemdetailmod .detailcontents {
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: left;
  letter-spacing: -1.20px;
  color: rgba(38, 38, 38, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 30.00px;
  font-weight: 700;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 100%;
height: auto;
left: 0.69px;
top: 0.00px;

}

.mrecruit .contentbody .recruitlistmod .recruitexpanded .recruititemdetailmod .bottomline {
  position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(190, 190, 190, 1);
          stroke-width: 1.00px;
          stroke-linecap: butt;
      box-shadow: none;
      width: 681.19px;
    height: 1.00px;
    left: 0.50px;

}

.mrecruit .contentbody .seperateline {
  position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(245, 245, 245, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: 16.00px;
    left: 0.00px;
    top: 3284.00px;
    right: 0.00px
}