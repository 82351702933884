

.imagebox {
    position: absolute;
    width: 1200.0px;
    height: 612.0px;
    overflow-x: hidden;
    overflow-y: hidden;
    left: 83.0px;
    top: 0.0px;
}

.imagebox .imageview {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      overflow: hidden;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 6400.00px;
    height: 612.00px;
    left: 0.00px;
    top: 0.00px;
    transition: all 0.5s ease;
}

.imagebox .imageview .imagecontent {
    display: table;
    float: left;
    width: 1200px;
    height: 612px;
}

.imagebox .imageview .imagecontent .picture {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.imageboxportrait {
  position: absolute;
  width: 757.0px;
  height: 1122.0px;
  overflow-x: hidden;
  overflow-y: hidden;
  left: 0.0px;
  top: 55.0px;
}

.imageboxportrait .imageview {
  position: absolute;
    box-sizing: content-box;
    -ms-transform: rotate(0.00deg);
  -webkit-transform: rotate(0.00deg);
  transform: rotate(0.00deg);
  -ms-transform-origin: center;
  -webkit-transform-origin: center;
  transform-origin: center;
    opacity: 1;
    cursor: default;
    overflow: hidden;
    margin-top: 0.00px;
    margin-right: 0.00px;
    margin-bottom: 0.00px;
    margin-left: 0.00px;
    padding-top: 0.00px;
    padding-right: 0.00px;
    padding-bottom: 0.00px;
    padding-left: 0.00px;
    background-color: rgba(255, 255, 255, 0);
    border-bottom-color: rgba(112, 112, 112, 1);
    border-bottom-style: none;
    border-bottom-width: 1.00px;
    border-left-color: rgba(112, 112, 112, 1);
    border-left-style: none;
    border-left-width: 1.00px;
    border-right-color: rgba(112, 112, 112, 1);
    border-right-style: none;
    border-right-width: 1.00px;
    border-top-color: rgba(112, 112, 112, 1);
    border-top-style: none;
    border-top-width: 1.00px;
    border-top-left-radius: 4.00px;
    border-top-right-radius: 4.00px;
    border-bottom-left-radius: 4.00px;
    border-bottom-right-radius: 4.00px;
    box-shadow: none;
    width: 757.00px;
  height: 5610.00px;
  left: 0.00px;
  top: 0.00px;
  transition: all 0.5s ease;
}

.imageboxportrait .imageview .imagecontent {
  display: table;
  float: left;
  width: 757px;
  height: 1122px;
}

.imageboxportrait .imageview .imagecontent .picture {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.sliderright {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 55.00px;
      letter-spacing: 0.00px;
      color: rgba(84, 84, 84, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 52.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumGothic, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 28.00px;
    height: 52.00px;
    right: 0.00px;
}

.sliderleft {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(180.00deg);
    -webkit-transform: rotate(180.00deg);
    transform: rotate(180.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 55.00px;
      letter-spacing: 0.00px;
      color: rgba(84, 84, 84, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 52.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumGothic, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 28.00px;
    height: 52.00px;
    left: 0.00px;
}

.thumb {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 171.00px;
    height: 101.00px;
}

.thumb .selected {
    box-sizing: border-box !important;
    border-bottom-color: rgba(167, 154, 255, 1);
    border-bottom-style: solid;
    border-bottom-width: 4.00px;
    border-left-color: rgba(167, 154, 255, 1);
    border-left-style: solid;
    border-left-width: 4.00px;
    border-right-color: rgba(167, 154, 255, 1);
    border-right-style: solid;
    border-right-width: 4.00px;
    border-top-color: rgba(167, 154, 255, 1);
    border-top-style: solid;
    border-top-width: 4.00px;
}

.thumb0 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("pages/games/assets/thumb0msl.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 171.00px;
    height: 101.00px;
    left: 0.00px;
    top: 0.00px;

}

.thumb1 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("pages/games/assets/thumb1msl.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 171.00px;
    height: 101.00px;
    left: 204.00px;
    top: 0.00px;

}

.thumb2 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("pages/games/assets/thumb2msl.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 171.00px;
    height: 101.00px;
    left: 408.00px;
    top: 0.00px;

}

.thumb3 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("pages/games/assets/thumb3msl.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 171.00px;
    height: 101.00px;
    left: 612.00px;
    top: 0.00px;

}

.thumb4 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("pages/games/assets/thumb4msl.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 171.00px;
    height: 101.00px;
    left: 816.00px;
    top: 0.00px;

}

.selected {
    box-sizing: border-box !important;
    border-bottom-color: rgba(167, 154, 255, 1);
    border-bottom-style: solid;
    border-bottom-width: 4.00px;
    border-left-color: rgba(167, 154, 255, 1);
    border-left-style: solid;
    border-left-width: 4.00px;
    border-right-color: rgba(167, 154, 255, 1);
    border-right-style: solid;
    border-right-width: 4.00px;
    border-top-color: rgba(167, 154, 255, 1);
    border-top-style: solid;
    border-top-width: 4.00px;
}


.imagelistthumbframe {
  position: absolute;
  width: 895.0px;
  height: 101.0px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.imagelistthumbframe .imagelistthumbcontent {
  position: absolute;
    box-sizing: content-box;
    -ms-transform: rotate(0.00deg);
  -webkit-transform: rotate(0.00deg);
  transform: rotate(0.00deg);
  -ms-transform-origin: center;
  -webkit-transform-origin: center;
  transform-origin: center;
    opacity: 1;
    cursor: default;
    overflow: hidden;
    margin-top: 0.00px;
    margin-right: 0.00px;
    margin-bottom: 0.00px;
    margin-left: 0.00px;
    padding-top: 0.00px;
    padding-right: 0.00px;
    padding-bottom: 0.00px;
    padding-left: 0.00px;
    background-color: rgba(255, 255, 255, 0);
    border-bottom-color: rgba(112, 112, 112, 1);
    border-bottom-style: none;
    border-bottom-width: 1.00px;
    border-left-color: rgba(112, 112, 112, 1);
    border-left-style: none;
    border-left-width: 1.00px;
    border-right-color: rgba(112, 112, 112, 1);
    border-right-style: none;
    border-right-width: 1.00px;
    border-top-color: rgba(112, 112, 112, 1);
    border-top-style: none;
    border-top-width: 1.00px;
    border-top-left-radius: 4.00px;
    border-top-right-radius: 4.00px;
    border-bottom-left-radius: 4.00px;
    border-bottom-right-radius: 4.00px;
    box-shadow: none;
    width: 1200.00px;
  height: 101.00px;
  left: 0.00px;
  top: 0.00px;
  transition: all 0.5s ease;
}
