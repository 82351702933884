

      html {
        height: 100%;
        width: 100%;
      }

      body {
        font-family: 'Open Sans', serif;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        -webkit-font-smoothing: antialiased;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
      }

      

      .games {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: hidden;
      overflow-y: hidden;
      background-color: rgba(255, 255, 255, 1);
      width: auto;
    height: 5647.00px;
    left: 0px;
    top: 0px;
    right: 0px;

}

.games .footer {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: auto;
    height: 116.50px;
    left: 0.00px;
    top: 5530.50px;
    right: 0.00px;

}

.games .footer .x10839 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      display: none;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x10839.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 49.00px;
    height: 49.00px;
    left: 1511.00px;
    top: -1269.50px;

}

.games .footer .x10839 .x10432 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x10432.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 49.00px;
    height: 49.00px;
    left: 0.00px;
    top: 0.00px;

}

.games .footer .x10839 .x10432 .x2253 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(0, 0, 0, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 49.00px;
    height: 49.00px;
    left: 0.00px;
    top: 0.00px;

}

.games .footer .x10839 .x10432 .x3910 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(-90.00deg);
    -webkit-transform: rotate(-90.00deg);
    transform: rotate(-90.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 0.6000000238418579;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: transparent;
      stroke: rgba(255, 255, 255, 1);
          stroke-width: 3.00px;
          stroke-linecap: round;
      box-shadow: none;
      width: 14.97px;
    height: 23.94px;
    left: 16.99px;
    top: 11.01px;

}

.games .footer .x10996 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -960px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1920.00px;
    height: 116.50px;
    left: 50%;
    top: 0.00px;

}

.games .footer .x10996 .x2252 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(49, 49, 49, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 1920.00px;
    height: 116.50px;
    left: 0.00px;
    top: 0.00px;

}

.games .footer .x10996 .smartStudyGamesCoLtdAllRightsReserved {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 24.00px;
      letter-spacing: 0.00px;
      color: rgba(190, 190, 190, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 13.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 293.00px;
    height: 14.00px;
    left: 605.00px;
    top: 48.00px;

}

.games .footer .x10996 .logosmartstudygames2500x640 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/logosmartstudygames2500x640.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 199.00px;
    height: 51.00px;
    left: 352.00px;
    top: 35.00px;

}

.games .contentbody {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: auto;
    height: 5446.00px;
    left: 0.00px;
    top: 85.00px;
    right: 0.00px;

}

.games .contentbody .x07b78e3d {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 0.6000000238418579;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(255, 255, 255, 0);
      box-shadow: none;
      width: auto;
    height: 1251.54px;
    left: 0.00px;
    top: 3689.46px;
    right: 0.00px;

}

.games .contentbody .xa2c79dc3 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: auto;
    height: 505.00px;
    left: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}

.games .contentbody .xa2c79dc3 .x39c19ec6 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x39c19ec6.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 0.9081169962882996;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: 505.00px;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;

}

.games .contentbody .xa2c79dc3 .x10997 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -215px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 430.00px;
    height: 141.00px;
    left: 50%;
    top: 220.00px;

  border: none;
  outline: none;
}
.games .contentbody .xa2c79dc3 .x10997 .x2255 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(122, 104, 245, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 33.00px;
      border-top-right-radius: 33.00px;
      border-bottom-left-radius: 33.00px;
      border-bottom-right-radius: 33.00px;
      -webkit-filter: drop-shadow(0px 3px 6px rgba(115, 115, 115, 0.1607843137254902));
    filter: drop-shadow(0px 3px 6px rgba(115, 115, 115, 0.1607843137254902));
      width: 268.00px;
    height: 66.00px;
    left: 81.00px;
    top: 75.00px;

}

.games .contentbody .xa2c79dc3 .x10997 .x {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 0.9800000190734863;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      letter-spacing: 0.00px;
      color: rgba(255, 255, 255, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 19.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 140.00px;
    height: 21.00px;
    left: 145.00px;
    top: 98.00px;

}

.games .contentbody .xa2c79dc3 .x10997 .xbea06a1a {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      line-height: 46.00px;
      letter-spacing: 0.00px;
      color: rgba(255, 255, 255, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 32.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1607843137254902));
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1607843137254902));
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 430.00px;
    height: 35.00px;
    left: 0.00px;
    top: -5.50px;

}




.games .contentbody .xe1fea43c {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -600px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1200.00px;
    height: 834.00px;
    left: 50%;
    top: 3876.00px;

}

.games .contentbody .xe1fea43c .x14d567e4 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 209.00px;
    height: 58.00px;
    left: 624.00px;
    bottom: 0.00px;

  border: none;
  outline: none;
}
.games .contentbody .xe1fea43c .x14d567e4 .x17561 {
    position: absolute;
      box-sizing: border-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(0, 0, 0, 1);
      border-bottom-color: rgba(175, 175, 175, 1);
      border-bottom-style: solid;
      border-bottom-width: 3.00px;
      border-left-color: rgba(175, 175, 175, 1);
      border-left-style: solid;
      border-left-width: 3.00px;
      border-right-color: rgba(175, 175, 175, 1);
      border-right-style: solid;
      border-right-width: 3.00px;
      border-top-color: rgba(175, 175, 175, 1);
      border-top-style: solid;
      border-top-width: 3.00px;
      border-top-left-radius: 33.00px;
      border-top-right-radius: 33.00px;
      border-bottom-left-radius: 33.00px;
      border-bottom-right-radius: 33.00px;
      box-shadow: none;
      width: 209.00px;
    height: 58.00px;
    left: 0.00px;
    top: 0.00px;

}

.games .contentbody .xe1fea43c .x14d567e4 .appStore {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 48.00px;
      letter-spacing: 0.00px;
      color: rgba(255, 255, 255, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 97.00px;
    height: 22.00px;
    left: 77.00px;
    top: 13.00px;

}

.games .contentbody .xe1fea43c .x14d567e4 .downloadOnThe {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 47.00px;
      letter-spacing: 0.00px;
      color: rgba(210, 210, 210, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 12.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 92.00px;
    height: 13.00px;
    left: 77.00px;
    top: -7.00px;

}

.games .contentbody .xe1fea43c .x14d567e4 .x204eafa43 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x204eafa43.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 41.00px;
    height: 42.00px;
    left: 33.00px;
    top: 7.00px;

}




.games .contentbody .xe1fea43c .x41a4f56c {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 209.00px;
    height: 58.00px;
    left: 368.00px;
    bottom: 0.00px;

  border: none;
  outline: none;
}
.games .contentbody .xe1fea43c .x41a4f56c .x17562 {
    position: absolute;
      box-sizing: border-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(0, 0, 0, 1);
      border-bottom-color: rgba(175, 175, 175, 1);
      border-bottom-style: solid;
      border-bottom-width: 3.00px;
      border-left-color: rgba(175, 175, 175, 1);
      border-left-style: solid;
      border-left-width: 3.00px;
      border-right-color: rgba(175, 175, 175, 1);
      border-right-style: solid;
      border-right-width: 3.00px;
      border-top-color: rgba(175, 175, 175, 1);
      border-top-style: solid;
      border-top-width: 3.00px;
      border-top-left-radius: 33.00px;
      border-top-right-radius: 33.00px;
      border-bottom-left-radius: 33.00px;
      border-bottom-right-radius: 33.00px;
      box-shadow: none;
      width: 209.00px;
    height: 58.00px;
    left: 0.00px;
    top: 0.00px;

}

.games .contentbody .xe1fea43c .x41a4f56c .googlePlay {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 48.00px;
      letter-spacing: 0.00px;
      color: rgba(255, 255, 255, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 19.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 108.00px;
    height: 21.00px;
    left: 72.00px;
    top: 13.50px;

}

.games .contentbody .xe1fea43c .x41a4f56c .getItOn {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 47.00px;
      letter-spacing: 0.00px;
      color: rgba(210, 210, 210, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 11.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 49.00px;
    height: 12.00px;
    left: 72.00px;
    top: -6.50px;

}

.games .contentbody .xe1fea43c .x41a4f56c .x1 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x1.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 31.00px;
    height: 35.00px;
    left: 34.00px;
    top: 13.00px;

}




.games .contentbody .xe1fea43c .xe47663c2 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: auto;
    height: 710.00px;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;

}

.games .contentbody .xe1fea43c .xe47663c2 .xac9160cd {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 55.00px;
      letter-spacing: 0.00px;
      color: rgba(100, 77, 157, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 34.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 560.00px;
    height: 38.00px;
    left: 320.00px;
    top: -8.50px;

}

.games .contentbody .xe1fea43c .xe47663c2 .xac9160cd .xac9160cd-0 {
    color: rgba(38, 38, 38, 1);
    font-weight: 700;
    font-style: normal;
    font-size: 34.00px;
    font-family: NanumSquare_ac, Arial;
    letter-spacing: 0;
    text-decoration: none;
}

.games .contentbody .xe1fea43c .xe47663c2 .xac9160cd .xac9160cd-11 {
    color: rgba(100, 77, 157, 1);
    font-weight: 700;
    font-style: normal;
    font-size: 34.00px;
    font-family: NanumSquare_ac, Arial;
    letter-spacing: 0;
    text-decoration: none;
}

.games .contentbody .xe1fea43c .xe47663c2 .x811860b4 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x811860b4.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 1200.00px;
    height: 612.00px;
    left: 0.00px;
    top: 98.00px;

}

.games .contentbody .xb04c4da7 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/xb04c4da7.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 0.20000000298023224;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(0, 0, 0, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: 1469.00px;
    left: 0.00px;
    top: 779.00px;
    right: 0.00px;

}

.games .contentbody .x6c600f49 {
    position: absolute;
      box-sizing: content-box;
      display: none;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 0.4000000059604645;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(233, 233, 233, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: 1537.00px;
    left: 0.00px;
    top: 4025.00px;
    right: 0.00px;

}

.games .contentbody .x275dc984 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: transparent;
      stroke: rgba(213, 213, 213, 1);
          stroke-width: 1.00px;
          stroke-linecap: butt;
      box-shadow: none;
      width: 1250.90px;
    height: 2.00px;
    top: 3688.00px;
    right: 333.55px;

}

.games .contentbody .sliderwrapperlatecia {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -683px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1366.00px;
    height: 749.00px;
    left: 50%;
    top: 2792.00px;

}

.games .contentbody .sliderwrapperlatecia .slidertaglatecia {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 100.00%;
    height: 100.00%;
    left: 0.00px;
    top: 0.00px;

  border: none;
  outline: none;
}
.games .contentbody .sliderwrapperlatecia .slidertaglatecia .imagesliderlatecia {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -683px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1366.00px;
    height: 749.00px;
    left: 50%;
    top: 0.00px;

}

.games .contentbody .sliderwrapperlatecia .slidertaglatecia .imagesliderlatecia .imagelistlatecia {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 987.00px;
    height: 101.00px;
    left: 190.00px;
    top: 648.00px;

}

.games .contentbody .sliderwrapperlatecia .slidertaglatecia .imagesliderlatecia .imagelistlatecia .thumb4latecia {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/thumb4latecia.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 171.00px;
    height: 101.00px;
    left: 816.00px;
    top: 0.00px;

}

.games .contentbody .sliderwrapperlatecia .slidertaglatecia .imagesliderlatecia .imagelistlatecia .thumb3latecia {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/thumb3latecia.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 171.00px;
    height: 101.00px;
    left: 612.00px;
    top: 0.00px;

}

.games .contentbody .sliderwrapperlatecia .slidertaglatecia .imagesliderlatecia .imagelistlatecia .thumb2latecia {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/thumb2latecia.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 171.00px;
    height: 101.00px;
    left: 408.00px;
    top: 0.00px;

}

.games .contentbody .sliderwrapperlatecia .slidertaglatecia .imagesliderlatecia .imagelistlatecia .thumb1latecia {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/thumb1latecia.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 171.00px;
    height: 101.00px;
    left: 204.00px;
    top: 0.00px;

}

.games .contentbody .sliderwrapperlatecia .slidertaglatecia .imagesliderlatecia .imagelistlatecia .thumb0latecia {
    position: absolute;
      box-sizing: border-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/thumb0latecia.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(167, 154, 255, 1);
      border-bottom-style: solid;
      border-bottom-width: 4.00px;
      border-left-color: rgba(167, 154, 255, 1);
      border-left-style: solid;
      border-left-width: 4.00px;
      border-right-color: rgba(167, 154, 255, 1);
      border-right-style: solid;
      border-right-width: 4.00px;
      border-top-color: rgba(167, 154, 255, 1);
      border-top-style: solid;
      border-top-width: 4.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 171.00px;
    height: 101.00px;
    left: 0.00px;
    top: 0.00px;

}

.games .contentbody .sliderwrapperlatecia .slidertaglatecia .imagesliderlatecia .imageviewlatecia {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/imageviewlatecia.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 1200.00px;
    height: 612.00px;
    left: 83.00px;
    top: 0.00px;

}

.games .contentbody .sliderwrapperlatecia .slidertaglatecia .imagesliderlatecia .sliderrightlatecia {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 55.00px;
      letter-spacing: 0.00px;
      color: rgba(84, 84, 84, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 52.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumGothic, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 28.00px;
    height: 60.00px;
    left: 1338.00px;
    top: 278.50px;

}

.games .contentbody .sliderwrapperlatecia .slidertaglatecia .imagesliderlatecia .sliderleftlatecia {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(180.00deg);
    -webkit-transform: rotate(180.00deg);
    transform: rotate(180.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 55.00px;
      letter-spacing: 0.00px;
      color: rgba(84, 84, 84, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 52.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumGothic, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 28.00px;
    height: 60.00px;
    left: 0.00px;
    top: 278.50px;

}




.games .contentbody .x830604a8 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -600px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1200.00px;
    height: 382.00px;
    left: 50%;
    top: 2347.00px;

}

.games .contentbody .x830604a8 .x17631 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: transparent;
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}

.games .contentbody .x830604a8 .x01f30b5ff9 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x01f30b5ff9.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 552.00px;
    height: 327.00px;
    left: 0.00px;
    top: 29.00px;

}

.games .contentbody .x830604a8 .x2705e8c4 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 55.00px;
      letter-spacing: 0.00px;
      color: rgba(38, 38, 38, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 32.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 110.00px;
    height: 35.00px;
    left: 628.00px;
    top: 18.00px;

}

.games .contentbody .x830604a8 .launchingIn2024 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 55.00px;
      letter-spacing: 0.00px;
      color: rgba(255, 96, 159, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 22.00px;
      font-weight: 700;
    font-style: normal;
      font-family: 'Gmarket Sans TTF', Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 257.00px;
    height: 22.00px;
    left: 896.00px;
    top: 18.50px;

}

.games .contentbody .x830604a8 .x800 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.85px;
      color: rgba(55, 55, 55, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 17.00px;
      font-weight: 300;
    font-style: normal;
      font-family: 'Spoqa Han Sans Neo', Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: auto;
      overflow-y: auto;
      width: 483.00px;
    height: 103.00px;
    left: 628.00px;
    top: 82.50px;

}

.games .contentbody .x830604a8 .x0c8ed0b1 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.85px;
      color: rgba(122, 104, 245, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 17.00px;
      font-weight: 500;
    font-style: normal;
      font-family: 'Spoqa Han Sans Neo', Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      width: 305.00px;
    height: 31.00px;
    left: 628.00px;
    top: 202.50px;

}

.games .contentbody .x830604a8 .latecialink {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 260.74px;
    height: 49.76px;
    left: 735.26px;
    top: 247.62px;

  border: none;
  outline: none;
}
.games .contentbody .x830604a8 .latecialink .x7061 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(100, 77, 157, 1);
      -webkit-filter: drop-shadow(0px 10px 6px rgba(158, 158, 158, 0.1607843137254902));
    filter: drop-shadow(0px 10px 6px rgba(158, 158, 158, 0.1607843137254902));
      width: 260.74px;
    height: 49.76px;
    left: 0.00px;
    top: 0.00px;

}

.games .contentbody .x830604a8 .latecialink .x033b615a {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 55.00px;
      letter-spacing: 0.00px;
      color: rgba(255, 255, 255, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 195.00px;
    height: 20.00px;
    left: 32.87px;
    top: -3.12px;

}




.games .contentbody .lateciamovelinkposition {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 228.00px;
    height: 2.00px;
    left: 360.00px;
    top: 2244.00px;

  border: none;
  outline: none;
}
.games .contentbody .lateciamovelinkposition .lateciasharppos {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: transparent;
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}




.games .contentbody .x4283a108 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -624.4500122070312px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: transparent;
      stroke: rgba(213, 213, 213, 1);
          stroke-width: 1.00px;
          stroke-linecap: butt;
      box-shadow: none;
      width: 1250.90px;
    height: 2.00px;
    left: 50%;
    top: 2247.00px;

}

.games .contentbody .sliderwrappermsl {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -683px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1366.00px;
    height: 756.00px;
    left: 50%;
    top: 1344.00px;

}

.games .contentbody .sliderwrappermsl .slidertagmsl {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 100.00%;
    height: 100.00%;
    left: 0.00px;
    top: 0.00px;

  border: none;
  outline: none;
}
.games .contentbody .sliderwrappermsl .slidertagmsl .imageslidermsl {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -683px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1366.00px;
    height: 756.00px;
    left: 50%;
    top: 0.00px;

}

.games .contentbody .sliderwrappermsl .slidertagmsl .imageslidermsl .imageviewmsl {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/imageviewmsl.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 1200.00px;
    height: 612.00px;
    left: 83.00px;
    top: 0.00px;

}

.games .contentbody .sliderwrappermsl .slidertagmsl .imageslidermsl .imagelistmsl {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 987.00px;
    height: 101.00px;
    left: 182.00px;
    top: 655.00px;

}

.games .contentbody .sliderwrappermsl .slidertagmsl .imageslidermsl .imagelistmsl .thumb4msl {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/thumb4msl.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 171.00px;
    height: 101.00px;
    left: 816.00px;
    top: 0.00px;

}

.games .contentbody .sliderwrappermsl .slidertagmsl .imageslidermsl .imagelistmsl .thumb3msl {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/thumb3msl.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 171.00px;
    height: 101.00px;
    left: 612.00px;
    top: 0.00px;

}

.games .contentbody .sliderwrappermsl .slidertagmsl .imageslidermsl .imagelistmsl .thumb2msl {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/thumb2msl.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 171.00px;
    height: 101.00px;
    left: 408.00px;
    top: 0.00px;

}

.games .contentbody .sliderwrappermsl .slidertagmsl .imageslidermsl .imagelistmsl .thumb1msl {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/thumb1msl.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 171.00px;
    height: 101.00px;
    left: 204.00px;
    top: 0.00px;

}

.games .contentbody .sliderwrappermsl .slidertagmsl .imageslidermsl .imagelistmsl .thumb0msl {
    position: absolute;
      box-sizing: border-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/thumb0msl.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(167, 154, 255, 1);
      border-bottom-style: solid;
      border-bottom-width: 4.00px;
      border-left-color: rgba(167, 154, 255, 1);
      border-left-style: solid;
      border-left-width: 4.00px;
      border-right-color: rgba(167, 154, 255, 1);
      border-right-style: solid;
      border-right-width: 4.00px;
      border-top-color: rgba(167, 154, 255, 1);
      border-top-style: solid;
      border-top-width: 4.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 171.00px;
    height: 101.00px;
    left: 0.00px;
    top: 0.00px;

}

.games .contentbody .sliderwrappermsl .slidertagmsl .imageslidermsl .sliderrightmsl {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 55.00px;
      letter-spacing: 0.00px;
      color: rgba(84, 84, 84, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 52.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumGothic, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 28.00px;
    height: 60.00px;
    left: 1338.00px;
    top: 278.50px;

}

.games .contentbody .sliderwrappermsl .slidertagmsl .imageslidermsl .sliderleftmsl {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(180.00deg);
    -webkit-transform: rotate(180.00deg);
    transform: rotate(180.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 55.00px;
      letter-spacing: 0.00px;
      color: rgba(84, 84, 84, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 52.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumGothic, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 28.00px;
    height: 60.00px;
    left: 0.00px;
    top: 278.50px;

}




.games .contentbody .x42a15558 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -600px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1200.00px;
    height: 382.00px;
    left: 50%;
    top: 899.00px;

}

.games .contentbody .x42a15558 .x17632 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: transparent;
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}

.games .contentbody .x42a15558 .msl4 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/msl4.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 552.00px;
    height: 327.00px;
    left: 0.00px;
    top: 29.00px;

}

.games .contentbody .x42a15558 .x29e11fe7 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 55.00px;
      letter-spacing: 0.00px;
      color: rgba(100, 77, 157, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 32.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 193.00px;
    height: 35.00px;
    left: 638.00px;
    top: 18.00px;

}

.games .contentbody .x42a15558 .x800700Rpg {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.85px;
      color: rgba(55, 55, 55, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 17.00px;
      font-weight: 300;
    font-style: normal;
      font-family: 'Spoqa Han Sans Neo', Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      width: 483.00px;
    height: 103.00px;
    left: 638.00px;
    top: 99.50px;

}

.games .contentbody .x42a15558 .x924a5d08 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 168.00px;
    height: 47.00px;
    left: 828.00px;
    top: 257.00px;

  border: none;
  outline: none;
}
.games .contentbody .x42a15558 .x924a5d08 .x17617 {
    position: absolute;
      box-sizing: border-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(0, 0, 0, 1);
      border-bottom-color: rgba(175, 175, 175, 1);
      border-bottom-style: solid;
      border-bottom-width: 3.00px;
      border-left-color: rgba(175, 175, 175, 1);
      border-left-style: solid;
      border-left-width: 3.00px;
      border-right-color: rgba(175, 175, 175, 1);
      border-right-style: solid;
      border-right-width: 3.00px;
      border-top-color: rgba(175, 175, 175, 1);
      border-top-style: solid;
      border-top-width: 3.00px;
      border-top-left-radius: 33.00px;
      border-top-right-radius: 33.00px;
      border-bottom-left-radius: 33.00px;
      border-bottom-right-radius: 33.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}

.games .contentbody .x42a15558 .x924a5d08 .appStore9fb6fc7f {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 36.00px;
      letter-spacing: 0.00px;
      color: rgba(255, 255, 255, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 15.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 72.00px;
    height: 17.00px;
    left: 61.00px;
    top: 9.50px;

}

.games .contentbody .x42a15558 .x924a5d08 .downloadOnThe45165995 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 35.00px;
      letter-spacing: 0.00px;
      color: rgba(210, 210, 210, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 9.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 69.00px;
    height: 10.00px;
    left: 61.00px;
    top: -6.50px;

}

.games .contentbody .x42a15558 .x924a5d08 .x2 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 34.00px;
    height: 33.00px;
    left: 26.00px;
    top: 5.00px;

}




.games .contentbody .x42a15558 .x8f22233e {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 169.00px;
    height: 47.00px;
    left: 638.00px;
    top: 257.00px;

  border: none;
  outline: none;
}
.games .contentbody .x42a15558 .x8f22233e .x17618 {
    position: absolute;
      box-sizing: border-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(0, 0, 0, 1);
      border-bottom-color: rgba(175, 175, 175, 1);
      border-bottom-style: solid;
      border-bottom-width: 3.00px;
      border-left-color: rgba(175, 175, 175, 1);
      border-left-style: solid;
      border-left-width: 3.00px;
      border-right-color: rgba(175, 175, 175, 1);
      border-right-style: solid;
      border-right-width: 3.00px;
      border-top-color: rgba(175, 175, 175, 1);
      border-top-style: solid;
      border-top-width: 3.00px;
      border-top-left-radius: 33.00px;
      border-top-right-radius: 33.00px;
      border-bottom-left-radius: 33.00px;
      border-bottom-right-radius: 33.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}

.games .contentbody .x42a15558 .x8f22233e .googlePlay0e178c3a {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 35.00px;
      letter-spacing: 0.00px;
      color: rgba(255, 255, 255, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 14.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 79.00px;
    height: 16.00px;
    left: 58.00px;
    top: 10.50px;

}

.games .contentbody .x42a15558 .x8f22233e .getItOn85e7d694 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 38.00px;
      letter-spacing: 0.00px;
      color: rgba(210, 210, 210, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 9.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 40.00px;
    height: 10.00px;
    left: 58.00px;
    top: -7.00px;

}

.games .contentbody .x42a15558 .x8f22233e .x1619f185b {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x1619f185b.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 25.00px;
    height: 27.00px;
    left: 28.00px;
    top: 9.00px;

}




.games .contentbody .x42a15558 .facebookicon {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 39.71px;
    height: 39.71px;
    left: 912.15px;
    top: 28.00px;

  border: none;
  outline: none;
}
.games .contentbody .x42a15558 .facebookicon .facebookroundedsolidiconIconscom61562 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(100, 77, 157, 1);
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: -0.00px;
    bottom: -0.00px;

}




.games .contentbody .x42a15558 .instaicon {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 39.71px;
    height: 39.71px;
    left: 1039.29px;
    top: 28.00px;

  border: none;
  outline: none;
}
.games .contentbody .x42a15558 .instaicon .x17601 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(100, 77, 157, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 3.15px;
    height: 3.25px;
    left: 23.69px;
    top: 12.56px;

}

.games .contentbody .x42a15558 .instaicon .x7056 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(100, 77, 157, 1);
      box-shadow: none;
      width: 16.41px;
    height: 10.46px;
    left: 11.65px;
    top: 17.52px;

}

.games .contentbody .x42a15558 .instaicon .x7057 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(100, 77, 157, 1);
      box-shadow: none;
      width: 6.22px;
    height: 5.30px;
    left: 16.74px;
    top: 17.62px;

}

.games .contentbody .x42a15558 .instaicon .x7058 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(100, 77, 157, 1);
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: -0.00px;
    bottom: -0.00px;

}




.games .contentbody .x42a15558 .youtubeicon {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 39.71px;
    height: 39.71px;
    left: 975.72px;
    top: 28.00px;

  border: none;
  outline: none;
}
.games .contentbody .x42a15558 .youtubeicon .x7059 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(100, 77, 157, 1);
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: -0.00px;
    bottom: -0.00px;

}

.games .contentbody .x42a15558 .youtubeicon .x7060 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(100, 77, 157, 1);
      box-shadow: none;
      width: 7.61px;
    height: 8.79px;
    left: 16.37px;
    top: 15.40px;

}




.games .contentbody .mslmovelinkposition {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 228.00px;
    height: 2.00px;
    left: 360.00px;
    top: 795.00px;

  border: none;
  outline: none;
}
.games .contentbody .mslmovelinkposition .mslsharppos {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: transparent;
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}




.games .contentbody .x4402c9a7 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: auto;
    height: 781.00px;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;

}

.games .contentbody .x4402c9a7 .msl {
    position: absolute;
      box-sizing: content-box;
      display: none;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/msl.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 1920.00px;
    height: 780.00px;
    left: 0.00px;
    top: 0.00px;

}

.games .contentbody .x4402c9a7 .x3 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x3.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: auto;
    height: 780.00px;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;

}

.games .contentbody .x4402c9a7 .x3 .topbg {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/topbg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(0, 0, 0, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      -webkit-filter: blur(20.162500381469727px);
  filter: blur(20.162500381469727px);
      width: auto;
    height: 780.00px;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;

}

.games .contentbody .x4402c9a7 .x3 .x01 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 0.3499999940395355;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(78, 78, 78, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 1920.00px;
    height: 780.00px;
    left: 0.00px;
    top: 0.00px;

}

.games .contentbody .x4402c9a7 .videoelement {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 271.00px;
    height: 117.00px;
    left: 152.00px;
    top: 205.00px;

  border: none;
  outline: none;
}
.games .contentbody .x4402c9a7 .videoelement .dummy {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: transparent;
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}




.games .contentbody .x4402c9a7 .x17611 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 0.4547539949417114;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(0, 0, 0, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: 781.00px;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;

}

.games .contentbody .x4402c9a7 .x10998 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -232px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 464.00px;
    height: 109.00px;
    left: 50%;
    top: 321.00px;

}

.games .contentbody .x4402c9a7 .x10998 .xeaa1b4c6 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      line-height: 68.00px;
      letter-spacing: 0.55px;
      color: rgba(255, 255, 255, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 55.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      -webkit-filter: drop-shadow(0px 3px 6px rgba(187, 187, 187, 0.1607843137254902));
    filter: drop-shadow(0px 3px 6px rgba(187, 187, 187, 0.1607843137254902));
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 190.00px;
    height: 61.00px;
    left: 137.00px;
    top: -3.50px;

}

.games .contentbody .x4402c9a7 .x10998 .x13b75286 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      line-height: 30.00px;
      letter-spacing: 0.00px;
      color: rgba(255, 255, 255, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 22.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 464.00px;
    height: 25.00px;
    left: 0.00px;
    top: 81.50px;

}

.games .header {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: auto;
    height: 85.00px;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;

}

.games .header .x17580 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 1920.00px;
    height: 85.00px;
    left: 0.00px;
    top: 0.00px;

}

.games .header .x11 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(201, 201, 201, 1);
          stroke-width: 1.00px;
          stroke-linecap: butt;
      -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1607843137254902));
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1607843137254902));
      width: 1942.50px;
    height: 28.00px;
    left: -7.50px;
    top: 78.00px;

}

.games .header .logo {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 199.00px;
    height: 51.00px;
    left: 80.00px;
    top: 20.00px;

  border: none;
  outline: none;
}
.games .header .logo .logostickyheader {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/logostickyheader.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 199.00px;
    height: 51.00px;
    left: 0.00px;
    top: 0.00px;

}




.games .header .headercontents {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 627.00px;
    height: 40.00px;
    top: 30.00px;
    right: 85.00px;

}

.games .header .headercontents .cultureelement {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 61.00px;
    height: 20.00px;
    left: 0.00px;
    top: 10.00px;

  border: none;
  outline: none;
}
.games .header .headercontents .cultureelement .xb54fc184 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 0.9800000190734863;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      letter-spacing: -0.45px;
      color: rgba(84, 84, 84, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 61.00px;
    height: 20.00px;
    left: 0.00px;
    top: 0.00px;

}




.games .header .headercontents .x2231 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(246, 246, 246, 1);
      border-bottom-color: rgba(67, 186, 166, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(67, 186, 166, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(67, 186, 166, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(67, 186, 166, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 20.00px;
      border-top-right-radius: 20.00px;
      border-bottom-left-radius: 20.00px;
      border-bottom-right-radius: 20.00px;
      box-shadow: none;
      width: 142.00px;
    height: 40.00px;
    left: 485.00px;
    top: 0.00px;

}

.games .header .headercontents .recruitelement {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 75.75px;
    height: 17.53px;
    left: 519.00px;
    top: 11.47px;

  border: none;
  outline: none;
}
.games .header .headercontents .recruitelement .xbed0c268 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 0.9800000190734863;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      letter-spacing: 0.00px;
      color: rgba(163, 133, 245, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 15.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 52.00px;
    height: 17.00px;
    left: 23.75px;
    top: 0.53px;

}

.games .header .headercontents .recruitelement .x117 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 0.6399999856948853;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(83, 83, 83, 1);
      box-shadow: none;
      width: 14.76px;
    height: 16.23px;
    left: 0.00px;
    top: 0.00px;

}




.games .header .headercontents .gameselement {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 61.00px;
    height: 20.00px;
    top: 10.00px;
    right: 429.00px;

  border: none;
  outline: none;
}
.games .header .headercontents .gameselement .xb95417a0 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 0.9800000190734863;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      letter-spacing: -0.45px;
      color: rgba(141, 55, 237, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 61.00px;
    height: 20.00px;
    left: 0.00px;
    top: 0.00px;

}




.games .header .headercontents .supportelement {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 61.00px;
    height: 20.00px;
    top: 10.00px;
    right: 296.00px;

  border: none;
  outline: none;
}
.games .header .headercontents .supportelement .x0a39e70c {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 0.9800000190734863;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      letter-spacing: -0.45px;
      color: rgba(84, 84, 84, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 61.00px;
    height: 20.00px;
    left: 0.00px;
    top: 0.00px;

}




.games .header .headercontents .humanreselement {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 46.00px;
    height: 20.00px;
    top: 10.00px;
    right: 173.00px;

  border: none;
  outline: none;
}
.games .header .headercontents .humanreselement .xa820cd77 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 0.9800000190734863;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      letter-spacing: -0.45px;
      color: rgba(84, 84, 84, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 46.00px;
    height: 20.00px;
    left: 0.00px;
    top: 0.00px;

}




