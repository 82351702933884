

.mhumanres .contentbody .checklist .checkboxselectedmod {

    display: block;
    position: absolute;
    box-sizing: content-box;
    -ms-transform: rotate(0.00deg);
  -webkit-transform: rotate(0.00deg);
  transform: rotate(0.00deg);
  -ms-transform-origin: center;
  -webkit-transform-origin: center;
  transform-origin: center;
    opacity: 1;
    
    margin-top: 0.00px;
    margin-right: 0.00px;
    margin-bottom: 0.00px;
    margin-left: 0.00px;
    padding-top: 0.00px;
    padding-right: 0.00px;
    padding-bottom: 0.00px;
    padding-left: 0.00px;
    overflow-x: unset;
    overflow-y: unset;
    background-color: transparent;
    width: 47.93px;
    height: 47.93px;
    left: 0.00px;
}


.mhumanres .contentbody .checklist .checkboxselected .bg1 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: transparent;
      border-bottom-color: rgba(151, 151, 151, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(151, 151, 151, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(151, 151, 151, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(151, 151, 151, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 47.93px;
    height: 47.93px;
    left: 0.00px;
    top: 0.00px;

}

.mhumanres .contentbody .checklist .checkboxselectedmod .bg2 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(90, 90, 90, 1);
      border-bottom-color: rgba(151, 151, 151, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(151, 151, 151, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(151, 151, 151, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(151, 151, 151, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 2.00px;
      border-top-right-radius: 2.00px;
      border-bottom-left-radius: 2.00px;
      border-bottom-right-radius: 2.00px;
      box-shadow: none;
      width: 39.94px;
    height: 39.94px;
    left: 3.99px;
    top: 3.99px;

}

.mhumanres .contentbody .checklist .checkboxselectedmod .bgcheck {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(255, 255, 255, 1);
      stroke: rgba(0, 0, 0, 0);
          stroke-width: 1.00px;
          stroke-linecap: butt;
      box-shadow: none;
      width: 21.97px;
    height: 17.98px;
    left: 12.98px;
    top: 14.98px;

}
