

      html {
        height: 100%;
        width: 100%;
      }

      body {
        font-family: 'Open Sans', serif;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        -webkit-font-smoothing: antialiased;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
      }

      

      .jdmobileui {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: auto;
      overflow-y: auto;
      background-color: rgba(255, 255, 255, 1);
      width: 1180.00px;
    height: 904.00px;
    left: 0px;
    top: 0px;

}

.jdmobileui .layer1 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1125.94px;
    height: 870.60px;
    left: 27.03px;
    top: 15.75px;

}

.jdmobileui .layer1 .thinkSmartStudyGamesIp2022RpgPvp {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      width: 1125.94px;
    height: 161.88px;
    left: 0.00px;
    top: -6.00px;

}

.jdmobileui .layer1 .x10981 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1065.37px;
    height: 55.14px;
    left: 22.87px;
    top: 619.75px;

}

.jdmobileui .layer1 .x10981 .xc8bb2dc1 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 64.00px;
    height: 21.00px;
    left: 238.66px;
    top: 28.14px;

}

.jdmobileui .layer1 .x10981 .xa8ad3157 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 64.00px;
    height: 21.00px;
    left: 476.10px;
    top: 28.14px;

}

.jdmobileui .layer1 .x10981 .xf994ddd1 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 64.00px;
    height: 21.00px;
    left: 946.62px;
    top: 28.14px;

}

.jdmobileui .layer1 .x10981 .x10980 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1065.37px;
    height: 29.20px;
    left: 0.00px;
    top: 0.00px;

}

.jdmobileui .layer1 .x10981 .x10980 .x10979 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 893.77px;
    height: 22.95px;
    left: 171.60px;
    top: 0.00px;

}

.jdmobileui .layer1 .x10981 .x10980 .x10979 .x151 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(255, 75, 161, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 896.96px;
    height: 3.19px;
    left: -1.60px;
    top: 9.88px;

}

.jdmobileui .layer1 .x10981 .x10980 .x10979 .x126 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(242, 33, 128, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 248.84px;
    height: 3.19px;
    left: 100.73px;
    top: 9.88px;

}

.jdmobileui .layer1 .x10981 .x10980 .x10979 .x150 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 356.61px;
    height: 3.66px;
    left: 461.99px;
    top: 9.41px;

}

.jdmobileui .layer1 .x10981 .x10980 .x10979 .x127 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(44, 128, 252, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 80.43px;
    height: 3.19px;
    left: 814.93px;
    top: 9.88px;

}

.jdmobileui .layer1 .x10981 .x10980 .x10979 .x239 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(255, 255, 255, 1);
      stroke: rgba(242, 33, 128, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 29.33px;
    height: 29.33px;
    left: 87.43px;
    top: -3.19px;

}

.jdmobileui .layer1 .x10981 .x10980 .x10979 .x240 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(255, 255, 255, 1);
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 29.33px;
    height: 29.33px;
    left: 323.39px;
    top: -3.19px;

}

.jdmobileui .layer1 .x10981 .x10980 .x10979 .x241 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(255, 255, 255, 1);
      stroke: rgba(44, 128, 252, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 29.33px;
    height: 29.33px;
    left: 790.39px;
    top: -3.19px;

}

.jdmobileui .layer1 .x10981 .x10980 .x10979 .x242 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(255, 255, 255, 1);
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 29.33px;
    height: 29.33px;
    left: 549.39px;
    top: -3.19px;

}

.jdmobileui .layer1 .x10981 .x10980 .x2bf2f01e {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 22.00px;
      letter-spacing: -0.80px;
      color: rgba(56, 56, 56, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 72.00px;
    height: 24.00px;
    left: 0.00px;
    top: 4.20px;

}

.jdmobileui .layer1 .x10981 .x94fcb3b0 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 64.00px;
    height: 21.00px;
    left: 702.61px;
    top: 28.14px;

}

.jdmobileui .layer1 .x10983 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1060.08px;
    height: 122.65px;
    left: 24.30px;
    top: 704.42px;

}

.jdmobileui .layer1 .x10983 .xd3d4b164 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 406.00px;
    height: 109.00px;
    left: 169.00px;
    top: 7.65px;

}

.jdmobileui .layer1 .x10983 .x20524483 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 166.00px;
    height: 51.00px;
    left: 170.48px;
    top: 24.32px;

}

.jdmobileui .layer1 .x10983 .x2127f1b0 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 22.00px;
      letter-spacing: -0.80px;
      color: rgba(56, 56, 56, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 72.00px;
    height: 24.00px;
    left: 0.00px;
    top: -1.00px;

}

.jdmobileui .layer1 .x10983 .lines0beb86be {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 889.44px;
    height: 0.00px;
    left: 170.64px;
    top: 4.53px;

}

.jdmobileui .layer1 .x10983 .lines0beb86be .x148ef114e2e {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(255, 75, 161, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 248.84px;
    height: 3.19px;
    left: -1.59px;
    top: -1.59px;

}

.jdmobileui .layer1 .x10983 .lines0beb86be .x14250f94d26 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(242, 33, 128, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 248.84px;
    height: 3.19px;
    left: 96.41px;
    top: -1.59px;

}

.jdmobileui .layer1 .x10983 .lines0beb86be .x1490145e1ee {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 310.49px;
    height: 3.19px;
    left: 341.54px;
    top: -1.59px;

}

.jdmobileui .layer1 .x10983 .lines0beb86be .x143c2aff2d6 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(44, 128, 252, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 310.49px;
    height: 3.19px;
    left: 580.54px;
    top: -1.59px;

}

.jdmobileui .layer1 .x10983 .lines0beb86be .x144f359e01f {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(51, 180, 149, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 70.81px;
    height: 3.19px;
    left: 820.23px;
    top: -1.59px;

}

.jdmobileui .layer1 .xHr {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(44, 128, 252, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 272.00px;
    height: 49.00px;
    left: 193.13px;
    top: 815.60px;

}

.jdmobileui .layer1 .xHr .xHr-0 {
    color: rgba(66, 66, 66, 1);
    font-weight: 400;
    font-style: normal;
    font-size: 18.00px;
    font-family: NanumSquare_ac, Arial;
    letter-spacing: -0.7200000000000001;
    text-decoration: none;
}

.jdmobileui .layer1 .xHr .xHr-8 {
    color: rgba(44, 128, 252, 1);
    font-weight: 400;
    font-style: normal;
    font-size: 18.00px;
    font-family: NanumSquare_ac, Arial;
    letter-spacing: -0.7200000000000001;
    text-decoration: none;
}

.jdmobileui .layer1 .x10987 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1059.60px;
    height: 139.94px;
    left: 22.39px;
    top: 306.48px;

}

.jdmobileui .layer1 .x10987 .xUiUi3Ui {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 416.00px;
    height: 111.00px;
    left: 178.96px;
    top: 22.94px;

}

.jdmobileui .layer1 .x10987 .x3deb46aa {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 22.00px;
      letter-spacing: -0.80px;
      color: rgba(56, 56, 56, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 72.00px;
    height: 24.00px;
    left: 0.00px;
    top: -1.00px;

}

.jdmobileui .layer1 .x10987 .lines1e0380ba {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 889.44px;
    height: 0.00px;
    left: 170.16px;
    top: 5.78px;

}

.jdmobileui .layer1 .x10987 .lines1e0380ba .x14802fa0b01 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(255, 75, 161, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 248.84px;
    height: 3.19px;
    left: -1.60px;
    top: -1.59px;

}

.jdmobileui .layer1 .x10987 .lines1e0380ba .x142d08b7efc {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(242, 33, 128, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 248.84px;
    height: 3.19px;
    left: 96.40px;
    top: -1.59px;

}

.jdmobileui .layer1 .x10987 .lines1e0380ba .x14972dc18db {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 310.49px;
    height: 3.19px;
    left: 341.54px;
    top: -1.59px;

}

.jdmobileui .layer1 .x10987 .lines1e0380ba .x1439624e22b {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(44, 128, 252, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 310.49px;
    height: 3.19px;
    left: 580.54px;
    top: -1.59px;

}

.jdmobileui .layer1 .x10987 .lines1e0380ba .x144d1f680bc {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(51, 180, 149, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 70.81px;
    height: 3.19px;
    left: 820.23px;
    top: -1.59px;

}

.jdmobileui .layer1 .x10985 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1059.79px;
    height: 113.39px;
    left: 22.56px;
    top: 475.74px;

}

.jdmobileui .layer1 .x10985 .xRpgUiUnity {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 291.00px;
    height: 81.00px;
    left: 176.58px;
    top: 26.39px;

}

.jdmobileui .layer1 .x10985 .x84ac589e {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 22.00px;
      letter-spacing: -0.60px;
      color: rgba(56, 56, 56, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 72.00px;
    height: 24.00px;
    left: 0.00px;
    top: -1.00px;

}

.jdmobileui .layer1 .x10985 .lines95a8d2f6 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 889.44px;
    height: 0.00px;
    left: 170.35px;
    top: 9.01px;

}

.jdmobileui .layer1 .x10985 .lines95a8d2f6 .x148418b2826 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(255, 75, 161, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 248.84px;
    height: 3.19px;
    left: -1.60px;
    top: -1.60px;

}

.jdmobileui .layer1 .x10985 .lines95a8d2f6 .x14252d33211 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(242, 33, 128, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 248.84px;
    height: 3.19px;
    left: 96.40px;
    top: -1.59px;

}

.jdmobileui .layer1 .x10985 .lines95a8d2f6 .x1495a43379f {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 310.49px;
    height: 3.19px;
    left: 341.54px;
    top: -1.60px;

}

.jdmobileui .layer1 .x10985 .lines95a8d2f6 .x143a1400423 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(44, 128, 252, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 310.49px;
    height: 3.19px;
    left: 580.54px;
    top: -1.59px;

}

.jdmobileui .layer1 .x10985 .lines95a8d2f6 .x144a96dda5a {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(51, 180, 149, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 70.81px;
    height: 3.19px;
    left: 820.22px;
    top: -1.59px;

}

.jdmobileui .layer1 .x11012 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1063.18px;
    height: 80.08px;
    left: 23.18px;
    top: 197.35px;

}

.jdmobileui .layer1 .x11012 .xLUi {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -0.72px;
      color: rgba(66, 66, 66, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 18.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 367.00px;
    height: 51.00px;
    left: 177.47px;
    top: 23.08px;

}

.jdmobileui .layer1 .x11012 .lines {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 889.44px;
    height: 0.00px;
    left: 173.74px;
    top: 9.00px;

}

.jdmobileui .layer1 .x11012 .lines .x148 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(255, 75, 161, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 248.84px;
    height: 3.19px;
    left: -1.60px;
    top: -1.60px;

}

.jdmobileui .layer1 .x11012 .lines .x142 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(242, 33, 128, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 248.84px;
    height: 3.19px;
    left: 96.40px;
    top: -1.60px;

}

.jdmobileui .layer1 .x11012 .lines .x149 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(68, 62, 156, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 310.49px;
    height: 3.19px;
    left: 341.54px;
    top: -1.60px;

}

.jdmobileui .layer1 .x11012 .lines .x143 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(44, 128, 252, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 310.49px;
    height: 3.19px;
    left: 580.54px;
    top: -1.60px;

}

.jdmobileui .layer1 .x11012 .lines .x144 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(51, 180, 149, 1);
          stroke-width: 3.19px;
          stroke-linecap: round;
      box-shadow: none;
      width: 70.81px;
    height: 3.19px;
    left: 820.23px;
    top: -1.60px;

}

.jdmobileui .layer1 .x11012 .x {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 22.00px;
      letter-spacing: -0.80px;
      color: rgba(56, 56, 56, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 72.00px;
    height: 24.00px;
    left: 0.00px;
    top: -1.00px;

}

