

.humanres .contentbody .checklist .checkboxselectedmod {

    display: block;
    position: absolute;
    box-sizing: content-box;
    -ms-transform: rotate(0.00deg);
  -webkit-transform: rotate(0.00deg);
  transform: rotate(0.00deg);
  -ms-transform-origin: center;
  -webkit-transform-origin: center;
  transform-origin: center;
    opacity: 1;
    cursor: default;
    margin-top: 0.00px;
    margin-right: 0.00px;
    margin-bottom: 0.00px;
    margin-left: 0.00px;
    padding-top: 0.00px;
    padding-right: 0.00px;
    padding-bottom: 0.00px;
    padding-left: 0.00px;
    overflow-x: unset;
    overflow-y: unset;
    background-color: transparent;
    width: 30.25px;
  height: 30.25px;
  left: 34.00px;

}


.humanres .contentbody .checklist .checkboxselected093b0835 .bg1 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: transparent;
      border-bottom-color: rgba(151, 151, 151, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(151, 151, 151, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(151, 151, 151, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(151, 151, 151, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 30.25px;
    height: 30.25px;
    left: 0.00px;
    top: 0.00px;

}

.humanres .contentbody .checklist .checkboxselectedmod .bg2 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(90, 90, 90, 1);
      border-bottom-color: rgba(151, 151, 151, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(151, 151, 151, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(151, 151, 151, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(151, 151, 151, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 2.00px;
      border-top-right-radius: 2.00px;
      border-bottom-left-radius: 2.00px;
      border-bottom-right-radius: 2.00px;
      box-shadow: none;
      width: 25.21px;
    height: 25.21px;
    left: 2.52px;
    top: 2.52px;

}

.humanres .contentbody .checklist .checkboxselectedmod .bgcheck {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(255, 255, 255, 1);
      stroke: rgba(0, 0, 0, 0);
          stroke-width: 1.00px;
          stroke-linecap: butt;
      box-shadow: none;
      width: 14.60px;
    height: 12.08px;
    left: 7.82px;
    top: 9.08px;

}
