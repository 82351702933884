

      html {
        height: 100%;
        width: 100%;
      }

      body {
        font-family: 'Open Sans', serif;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        -webkit-font-smoothing: antialiased;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
      }

      

      .mstickyheader {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: hidden;
      overflow-y: hidden;
      background-color: rgba(255, 255, 255, 1);
      width: auto;
    height: 101.00px;
    left: 0px;
    top: 0px;
    right: 0px;

}

.mstickyheader .x17612 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: transparent;
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: 101.00px;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;

}

.mstickyheader .x17636 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1607843137254902));
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1607843137254902));
      width: auto;
    height: 93.00px;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;

}

.mstickyheader .logosmartstudygames2500x640 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/logosmartstudygames2500x640.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 309.00px;
    height: 79.00px;
    left: 34.00px;
    top: 14.00px;

}

.mstickyheader .navibar {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 55.00px;
    height: 33.00px;
    top: 37.00px;
    right: 46.00px;

}

.mstickyheader .navibar .x17579 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(67, 67, 67, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 5.00px;
      border-top-right-radius: 5.00px;
      border-bottom-left-radius: 5.00px;
      border-bottom-right-radius: 5.00px;
      box-shadow: none;
      width: 55.00px;
    height: 5.00px;
    left: 0.00px;
    top: 0.00px;

}

.mstickyheader .navibar .x17580 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(67, 67, 67, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 5.00px;
      border-top-right-radius: 5.00px;
      border-bottom-left-radius: 5.00px;
      border-bottom-right-radius: 5.00px;
      box-shadow: none;
      width: 55.00px;
    height: 5.00px;
    left: 0.00px;
    top: 14.00px;

}

.mstickyheader .navibar .x17594 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(67, 67, 67, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 5.00px;
      border-top-right-radius: 5.00px;
      border-bottom-left-radius: 5.00px;
      border-bottom-right-radius: 5.00px;
      box-shadow: none;
      width: 55.00px;
    height: 5.00px;
    left: 0.00px;
    top: 28.00px;

}

