
.recruit .contentbodymod {

  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: auto;
    height: 3154.50px;
    left: 0.00px;
    top: 85.00px;
    right: -0.50px;

}


.recruit .contentbody .faqlistmod {

  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -600.25px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1200.50px;
    height: 938.50px;
    left: 50%;

}

.recruit .contentbody .faqlistmod .texttitle {
  position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 60.00px;
      letter-spacing: -1.60px;
      color: rgba(44, 42, 42, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 32.00px;
      font-weight: 400;
    font-style: normal;
      font-family: AppleSDGothicNeoB00, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 172.00px;
    height: 41.00px;
    left: 2.00px;
    top: -9.50px;

}

.recruit .contentbody .faqlistmod .upperline {
  position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(183, 183, 183, 1);
          stroke-width: 2.00px;
          stroke-linecap: butt;
      box-shadow: none;
      width: 1199.50px;
    height: 2.00px;
    left: 1.00px;
    top: 73.50px;

}

.recruit .contentbody .faqlistmod .faqitemmod {

  display: block;
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  overflow-x: unset;
  overflow-y: unset;
  background-color: transparent;
  width: 1200.00px;
height: 84.00px;
left: 0.00px;
top: 45.00px;

}

.recruit .contentbody .faqlistmod .faqitemmod .bottomline {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  stroke: rgba(223, 223, 223, 1);
      stroke-width: 1.00px;
      stroke-linecap: butt;
  box-shadow: none;
  width: 1199.00px;
height: 1.00px;
left: 1.00px;
top: 83.00px;

}

.recruit .contentbody .faqlistmod .faqitemmod .faqtitle {

  display: block;
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  overflow-x: unset;
  overflow-y: unset;
  background-color: transparent;
  width: 364.00px;
height: 22.00px;
left: 88.00px;
top: 33.00px;

}

.recruit .contentbody .faqlistmod .faqitemmod .faqtitle .txttitle {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: left;
  letter-spacing: 0.00px;
  color: rgba(38, 38, 38, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 20.00px;
  font-weight: 700;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 385.00px;
height: 22.00px;
left: 0.00px;
top: 0.00px;

}

.recruit .contentbody .faqlistmod .faqitemmod .iconq {

  display: block;
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  overflow-x: unset;
  overflow-y: unset;
  background-color: transparent;
  width: 40.00px;
height: 40.00px;
left: 22.00px;
top: 22.00px;

}

.recruit .contentbody .faqlistmod .faqitemmod .iconq .circleicon {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  fill: rgba(67, 65, 65, 1);
  box-shadow: none;
  width: 40.00px;
height: 40.00px;
left: 0.00px;
top: 0.00px;

}

.recruit .contentbody .faqlistmod .faqitemmod .iconq .txtquestion {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
  line-height: 44.00px;
  letter-spacing: -1.10px;
  color: rgba(255, 255, 255, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 22.00px;
  font-weight: 700;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 16.00px;
height: 25.00px;
left: 12.00px;
top: -1.50px;

}

.recruit .contentbody .faqlistmod .faqitemmod .expandmore {

  display: block;
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  background-image: url("assets/expandmore3.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  overflow-x: unset;
  overflow-y: unset;
  background-color: transparent;
  width: 33.00px;
height: 33.00px;
left: 1142.00px;
top: 28.00px;

}

.recruit .contentbody .faqlistmod .faqitemmod .itemrect {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: transparent;
  border-bottom-color: rgba(112, 112, 112, 1);
  border-bottom-style: none;
  border-bottom-width: 1.00px;
  border-left-color: rgba(112, 112, 112, 1);
  border-left-style: none;
  border-left-width: 1.00px;
  border-right-color: rgba(112, 112, 112, 1);
  border-right-style: none;
  border-right-width: 1.00px;
  border-top-color: rgba(112, 112, 112, 1);
  border-top-style: none;
  border-top-width: 1.00px;
  border-top-left-radius: 0.00px;
  border-top-right-radius: 0.00px;
  border-bottom-left-radius: 0.00px;
  border-bottom-right-radius: 0.00px;
  box-shadow: none;
  width: auto;
height: 84px;
left: 0.00px;
right: 0.00px;
}

.recruit .contentbody .faqlistmod .faqitemopened {

  display: block;
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  overflow-x: unset;
  overflow-y: unset;
  background-color: transparent;
  width: 1199.00px;
left: 1.00px;
top: 45.00px;

}

.recruit .contentbody .faqlistmod .faqitemopened .bottomline {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  stroke: rgba(223, 223, 223, 1);
      stroke-width: 1.00px;
      stroke-linecap: butt;
  box-shadow: none;
  width: 1199.00px;
height: 1.00px;
left: 0.00px;

}

.recruit .contentbody .faqlistmod .faqitemopened .faqdetail {

  display: block;
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  overflow-x: unset;
  overflow-y: unset;
  background-color: transparent;
  width: 994.00px;
height: auto;
left: 87.00px;
top: 88.50px;

}

.recruit .contentbody .faqlistmod .faqitemopened .faqdetail .textdetail {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 28.00px;
  letter-spacing: 0.00px;
  color: rgba(38, 38, 38, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 18.00px;
  font-weight: 400;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 100%;
height: auto;
left: 0.00px;
top: -4.00px;

}

.recruit .contentbody .faqlistmod .faqitemopened .faqtitleopened {

  display: block;
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  overflow-x: unset;
  overflow-y: unset;
  background-color: transparent;
  width: auto;
height: 22.00px;
left: 87.00px;
top: 14.00px;

}

.recruit .contentbody .faqlistmod .faqitemopened .faqtitleopened .texttitle {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: left;
  letter-spacing: 0.00px;
  color: rgba(100, 77, 157, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 20.00px;
  font-weight: 700;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: auto;
height: 22.00px;
left: 0.00px;
top: 0.00px;

}

.recruit .contentbody .faqlistmod .faqitemopened .iconqopened {

  display: block;
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  overflow-x: unset;
  overflow-y: unset;
  background-color: transparent;
  width: 40.00px;
height: 40.00px;
left: 21.00px;
top: 22.00px;

}

.recruit .contentbody .faqlistmod .faqitemopened .iconqopened .circleicon {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  fill: rgba(122, 104, 245, 1);
  box-shadow: none;
  width: 40.00px;
height: 40.00px;
left: 0.00px;
top: 0.00px;

}

.recruit .contentbody .faqlistmod .faqitemopened .iconqopened .textq {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
  line-height: 44.00px;
  letter-spacing: -1.10px;
  color: rgba(255, 255, 255, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 22.00px;
  font-weight: 700;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 16.00px;
height: 25.00px;
left: 12.00px;
top: -1.50px;

}

.recruit .contentbody .faqlistmod .faqitemopened .iconaopened {

  display: block;
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  overflow-x: unset;
  overflow-y: unset;
  background-color: transparent;
  width: 40.00px;
height: 40.00px;
left: 21.00px;
top: 85.00px;

}

.recruit .contentbody .faqlistmod .faqitemopened .iconaopened .circleicon {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  fill: rgba(229, 229, 229, 1);
  box-shadow: none;
  width: 40.00px;
height: 40.00px;
left: 0.00px;
top: 0.00px;

}

.recruit .contentbody .faqlistmod .faqitemopened .iconaopened .texta {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
  line-height: 44.00px;
  letter-spacing: -1.10px;
  color: rgba(122, 104, 245, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 22.00px;
  font-weight: 700;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 16.00px;
height: 25.00px;
left: 12.00px;
top: -1.50px;

}

.recruit .contentbody .faqlistmod .faqitemopened .expandmore {

  display: block;
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  background-image: url("assets/expandmore2.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  overflow-x: unset;
  overflow-y: unset;
  background-color: transparent;
  width: 33.00px;
height: 33.00px;
left: 1141.00px;
top: 22.00px;

}

.recruit .contentbody .recruittitlerect {
  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -600.5px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1201.00px;
    height: 71.00px;
    left: 50%;
    top: 842.00px;
}

.recruit .contentbody .recruitlistmod {

  display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -599.75px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 1199.50px;
    height: 999.00px;
    left: 50%;
    top: 923.00px;

}

.recruit .contentbody .recruitlistmod .buttonapply {

  display: block;
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  overflow-x: unset;
  overflow-y: unset;
  background-color: transparent;
  width: 311.00px;
height: 66.00px;
left: 445.00px;
bottom: 0.00px;

}

.recruit .contentbody .recruitlistmod .buttonapply .bgrect {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(100, 77, 157, 1);
  border-bottom-color: rgba(112, 112, 112, 1);
  border-bottom-style: none;
  border-bottom-width: 1.00px;
  border-left-color: rgba(112, 112, 112, 1);
  border-left-style: none;
  border-left-width: 1.00px;
  border-right-color: rgba(112, 112, 112, 1);
  border-right-style: none;
  border-right-width: 1.00px;
  border-top-color: rgba(112, 112, 112, 1);
  border-top-style: none;
  border-top-width: 1.00px;
  border-top-left-radius: 33.00px;
  border-top-right-radius: 33.00px;
  border-bottom-left-radius: 33.00px;
  border-bottom-right-radius: 33.00px;
  -webkit-filter: drop-shadow(0px 3px 6px rgba(149, 149, 149, 0.1607843137254902));
filter: drop-shadow(0px 3px 6px rgba(149, 149, 149, 0.1607843137254902));
  width: 311.00px;
height: 66.00px;
left: 0.00px;
top: 0.00px;

}

.recruit .contentbody .recruitlistmod .buttonapply .textapply {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 0.9800000190734863;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: -155.5px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
  vertical-align:middle;
  line-height: 66.00px;
  letter-spacing: 0.00px;
  color: rgba(255, 255, 255, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 19.00px;
  font-weight: 700;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 311.00px;
height: 21.00px;
left: 50%;
top: 0.00px;

}

.recruit .contentbody .recruitlistmod .upperline {
  position: absolute;
    box-sizing: content-box;
    -ms-transform: rotate(0.00deg);
  -webkit-transform: rotate(0.00deg);
  transform: rotate(0.00deg);
  -ms-transform-origin: center;
  -webkit-transform-origin: center;
  transform-origin: center;
    opacity: 1;
    
    margin-top: 0.00px;
    margin-right: 0.00px;
    margin-bottom: 0.00px;
    margin-left: 0.00px;
    padding-top: 0.00px;
    padding-right: 0.00px;
    padding-bottom: 0.00px;
    padding-left: 0.00px;
    stroke: rgba(223, 223, 223, 1);
        stroke-width: 1.00px;
        stroke-linecap: butt;
    box-shadow: none;
    width: 1199.00px;
  height: 1.00px;
  left: 0.00px;
  top: -0.50px;

}

.recruit .contentbody .recruitlistmod .recruititem {

  display: block;
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  overflow-x: unset;
  overflow-y: unset;
  background-color: transparent;
  width: 1199.00px;
height: 72.00px;
left: 0.00px;
top: 25.00px;

}

.recruit .contentbody .recruitlistmod .recruititem .tasktitle {

  display: block;
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  overflow-x: unset;
  overflow-y: unset;
  background-color: transparent;
  width: 304.00px;
height: 22.00px;
left: 14.00px;
top: 27.50px;

}

.recruit .contentbody .recruitlistmod .recruititem .tasktitle .texttitle {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: left;
  letter-spacing: -0.80px;
  color: rgba(38, 38, 38, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 20.00px;
  font-weight: 700;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: auto;
height: auto;
left: 0.00px;
top: 0.00px;

}

.recruit .contentbody .recruitlistmod .recruititem .jobgroup {

  display: block;
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  overflow-x: unset;
  overflow-y: unset;
  background-color: transparent;
  width: 142.00px;
height: 17.00px;
left: 14.00px;
top: 0.00px;

}

.recruit .contentbody .recruitlistmod .recruititem .jobgroup .texttitle {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: left;
  letter-spacing: -0.60px;
  color: rgba(125, 125, 125, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 15.00px;
  font-weight: 400;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 142.00px;
height: 17.00px;
left: 0.00px;
top: 0.00px;

}

.recruit .contentbody .recruitlistmod .recruititem .bottomline {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  stroke: rgba(223, 223, 223, 1);
      stroke-width: 1.00px;
      stroke-linecap: butt;
  box-shadow: none;
  width: 1199.00px;
height: 1.00px;
left: 0.00px;
top: 71.50px;

}

.recruit .contentbody .recruitlistmod .recruititem .newicon {

  display: block;
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  overflow-x: unset;
  overflow-y: unset;
  background-color: transparent;
  width: 24.00px;
height: 24.00px;
top: 28.00px;

}

.recruit .contentbody .recruitlistmod .recruititem .newicon .circleicon {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  fill: rgba(245, 97, 97, 1);
  box-shadow: none;
  width: 24.00px;
height: 24.00px;
left: 0.00px;
top: 0.00px;

}

.recruit .contentbody .recruitlistmod .recruititem .newicon .textn {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
  line-height: 24.00px;
  letter-spacing: -0.60px;
  color: rgba(255, 255, 255, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 12.00px;
  font-weight: 700;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 8.00px;
height: 13.00px;
left: 8.00px;
top: 0.50px;

}

.recruit .contentbody .recruitlistmod .recruititem .expandmore {

  display: block;
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  background-image: url("assets/expandmore1.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  overflow-x: unset;
  overflow-y: unset;
  background-color: transparent;
  width: 33.00px;
height: 33.00px;
left: 1141.00px;
top: 4.50px;

}


.recruit .contentbody .recruitlistmod .recruititemdetailmod {

  display: block;
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  overflow-x: unset;
  overflow-y: unset;
  background-color: transparent;
  width: 1199.00px;
height: auto;
top: 0.00px;
right: 0.00px;

}

.recruit .contentbody .recruitlistmod .recruititemdetailmod .detailcontents {
position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
-webkit-transform: rotate(0.00deg);
transform: rotate(0.00deg);
-ms-transform-origin: center;
-webkit-transform-origin: center;
transform-origin: center;
  opacity: 1;
  
  margin-top: 0.00px;
  margin-right: 0.00px;
  margin-bottom: 0.00px;
  margin-left: 0.00px;
  padding-top: 0.00px;
  padding-right: 0.00px;
  padding-bottom: 0.00px;
  padding-left: 0.00px;
  background-color: rgba(255, 255, 255, 0);
  text-align: left;
  letter-spacing: -0.80px;
  color: rgba(38, 38, 38, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 20.00px;
  font-weight: 700;
font-style: normal;
  font-family: NanumSquare_ac, Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  width: 100%;
height: auto;
left: 13.50px;
top: 0.00px;

}

.recruit .contentbody .recruitlistmod .recruititemdetailmod .bottomline {
  position: absolute;
    box-sizing: content-box;
    -ms-transform: rotate(0.00deg);
  -webkit-transform: rotate(0.00deg);
  transform: rotate(0.00deg);
  -ms-transform-origin: center;
  -webkit-transform-origin: center;
  transform-origin: center;
    opacity: 1;
    
    margin-top: 0.00px;
    margin-right: 0.00px;
    margin-bottom: 0.00px;
    margin-left: 0.00px;
    padding-top: 0.00px;
    padding-right: 0.00px;
    padding-bottom: 0.00px;
    padding-left: 0.00px;
    stroke: rgba(175, 175, 175, 1);
        stroke-width: 1.00px;
        stroke-linecap: butt;
    box-shadow: none;
    width: 1199.00px;
  height: 1.00px;
  left: 0.00px;

}

