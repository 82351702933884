

      html {
        height: 100%;
        width: 100%;
      }

      body {
        font-family: 'Open Sans', serif;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        -webkit-font-smoothing: antialiased;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
      }

      

      .mgames {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: hidden;
      overflow-y: hidden;
      background-color: rgba(255, 255, 255, 1);
      width: auto;
    height: 6395.00px;
    left: 0px;
    top: 0px;
    right: 0px;

}

.mgames .footer {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: auto;
    height: 137.50px;
    left: 0.00px;
    top: 6258.00px;
    right: 0.00px;

}

.mgames .footer .x2252 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(49, 49, 49, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: 137.50px;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;

}

.mgames .footer .smartStudyGamesCoLtdAllRightsReserved {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 24.00px;
      letter-spacing: 0.00px;
      color: rgba(190, 190, 190, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 451.00px;
    height: 22.00px;
    left: 248.00px;
    top: 56.00px;

}

.mgames .footer .logosmartstudygames2500x640 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/logosmartstudygames2500x640.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 199.00px;
    height: 51.00px;
    left: 29.00px;
    top: 45.00px;

}

.mgames .x11 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x11.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: auto;
    height: 600.00px;
    left: 0.00px;
    right: 0px;
    bottom: 136.00px;

}

.mgames .x11 .xdfdf89c0 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/xdfdf89c0.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 0.9081169962882996;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 1920.00px;
    height: 600.00px;
    left: -559.00px;
    top: 0.00px;

}

.mgames .x11 .x17539 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 0.4000000059604645;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(42, 42, 42, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: 600.00px;
    left: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}

.mgames .x139946b2 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -282px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 564.00px;
    height: 234.00px;
    left: 50%;
    top: 5813.50px;

  border: none;
  outline: none;
}
.mgames .x139946b2 .xd409be40 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      line-height: 62.00px;
      letter-spacing: 0.00px;
      color: rgba(255, 255, 255, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 42.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1607843137254902));
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1607843137254902));
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 564.00px;
    height: 47.00px;
    left: 0.00px;
    top: -7.50px;

}

.mgames .x139946b2 .x11029 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 335.00px;
    height: 82.00px;
    left: 115.00px;
    bottom: 0.00px;

}

.mgames .x139946b2 .x11029 .x17584 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(122, 104, 245, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 41.00px;
      border-top-right-radius: 41.00px;
      border-bottom-left-radius: 41.00px;
      border-bottom-right-radius: 41.00px;
      -webkit-filter: drop-shadow(0px 3px 6px rgba(83, 83, 83, 0.1607843137254902));
    filter: drop-shadow(0px 3px 6px rgba(83, 83, 83, 0.1607843137254902));
      width: 335.00px;
    height: 82.00px;
    left: 0.00px;
    top: 0.00px;

}

.mgames .x139946b2 .x11029 .x {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 0.9800000190734863;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      letter-spacing: 0.00px;
      color: rgba(255, 255, 255, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 26.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 192.00px;
    height: 29.00px;
    left: 71.00px;
    top: 25.00px;

}




.mgames .contentbody {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: hidden;
      overflow-y: hidden;
      background-color: transparent;
      width: 100.00%;
    height: 5416.00px;
    left: 0.00px;
    top: 101.00px;

}

.mgames .contentbody .x37 {
    position: absolute;
      box-sizing: content-box;
      display: none;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(189, 189, 189, 1);
          stroke-width: 1.00px;
          stroke-linecap: butt;
      box-shadow: none;
      width: 751.00px;
    height: 1.00px;
    left: -0.56px;
    top: 3830.00px;

}

.mgames .contentbody .artwallbg {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/artwallbg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 750.00px;
    height: 1085.00px;
    left: 0.00px;
    top: 1440.00px;

}

.mgames .contentbody .twi001t3226289l {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 0.1599999964237213;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(254, 254, 254, 1);
      box-shadow: none;
      width: auto;
    height: 1580.50px;
    left: 0.00px;
    top: 2990.00px;
    right: 2585.00px;

}

.mgames .contentbody .xc8c7c949 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -341px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 682.00px;
    height: 658.00px;
    left: 50%;
    top: 4758.00px;

}

.mgames .contentbody .xc8c7c949 .linkbutton {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 624.00px;
    height: 83.00px;
    left: 29.00px;
    top: 575.00px;

}

.mgames .contentbody .xc8c7c949 .linkbutton .appstorebutton {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 297.00px;
    height: 100.00%;
    top: 0.00px;
    right: 0.00px;

  border: none;
  outline: none;
}
.mgames .contentbody .xc8c7c949 .linkbutton .appstorebutton .x17587231d4ec3 {
    position: absolute;
      box-sizing: border-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(0, 0, 0, 1);
      border-bottom-color: rgba(175, 175, 175, 1);
      border-bottom-style: solid;
      border-bottom-width: 3.00px;
      border-left-color: rgba(175, 175, 175, 1);
      border-left-style: solid;
      border-left-width: 3.00px;
      border-right-color: rgba(175, 175, 175, 1);
      border-right-style: solid;
      border-right-width: 3.00px;
      border-top-color: rgba(175, 175, 175, 1);
      border-top-style: solid;
      border-top-width: 3.00px;
      border-top-left-radius: 40.00px;
      border-top-right-radius: 40.00px;
      border-bottom-left-radius: 40.00px;
      border-bottom-right-radius: 40.00px;
      box-shadow: none;
      width: 297.00px;
    height: 83.00px;
    left: 0.00px;
    top: 0.00px;

}

.mgames .contentbody .xc8c7c949 .linkbutton .appstorebutton .appStorec74069a6 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 64.00px;
      letter-spacing: 0.00px;
      color: rgba(255, 255, 255, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 27.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 130.00px;
    height: 30.00px;
    left: 108.00px;
    top: 18.00px;

}

.mgames .contentbody .xc8c7c949 .linkbutton .appstorebutton .downloadOnThee18afc9e {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 63.00px;
      letter-spacing: 0.00px;
      color: rgba(210, 210, 210, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 16.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 122.00px;
    height: 18.00px;
    left: 108.00px;
    top: -9.50px;

}

.mgames .contentbody .xc8c7c949 .linkbutton .appstorebutton .x2b138739b {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x2b138739b.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 59.00px;
    height: 60.00px;
    left: 47.00px;
    top: 9.00px;

}




.mgames .contentbody .xc8c7c949 .linkbutton .googleplaybutton {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 299.00px;
    height: 100.00%;
    left: 0.00px;
    top: 0.00px;

  border: none;
  outline: none;
}
.mgames .contentbody .xc8c7c949 .linkbutton .googleplaybutton .x17588b3c8ed1e {
    position: absolute;
      box-sizing: border-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(0, 0, 0, 1);
      border-bottom-color: rgba(175, 175, 175, 1);
      border-bottom-style: solid;
      border-bottom-width: 3.00px;
      border-left-color: rgba(175, 175, 175, 1);
      border-left-style: solid;
      border-left-width: 3.00px;
      border-right-color: rgba(175, 175, 175, 1);
      border-right-style: solid;
      border-right-width: 3.00px;
      border-top-color: rgba(175, 175, 175, 1);
      border-top-style: solid;
      border-top-width: 3.00px;
      border-top-left-radius: 40.00px;
      border-top-right-radius: 40.00px;
      border-bottom-left-radius: 40.00px;
      border-bottom-right-radius: 40.00px;
      box-shadow: none;
      width: 299.00px;
    height: 83.00px;
    left: 0.00px;
    top: 0.00px;

}

.mgames .contentbody .xc8c7c949 .linkbutton .googleplaybutton .googlePlaycbd82001 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 63.00px;
      letter-spacing: 0.00px;
      color: rgba(255, 255, 255, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 25.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 142.00px;
    height: 27.00px;
    left: 103.00px;
    top: 18.00px;

}

.mgames .contentbody .xc8c7c949 .linkbutton .googleplaybutton .getItOn1ee68e4c {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 64.00px;
      letter-spacing: 0.00px;
      color: rgba(210, 210, 210, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 15.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 67.00px;
    height: 17.00px;
    left: 103.00px;
    top: -9.50px;

}

.mgames .contentbody .xc8c7c949 .linkbutton .googleplaybutton .x1cda2d08a {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x1cda2d08a.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 45.00px;
    height: 48.00px;
    left: 49.00px;
    top: 17.00px;

}




.mgames .contentbody .xc8c7c949 .xafd0a833 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      line-height: 58.00px;
      letter-spacing: 0.00px;
      color: rgba(100, 77, 157, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 40.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 330.00px;
    height: 102.00px;
    left: 176.00px;
    top: -7.00px;

}

.mgames .contentbody .xc8c7c949 .xafd0a833 .xafd0a833-0 {
    color: rgba(38, 38, 38, 1);
    font-weight: 700;
    font-style: normal;
    font-size: 40.00px;
    font-family: NanumSquare_ac, Arial;
    letter-spacing: 0;
    text-decoration: none;
}

.mgames .contentbody .xc8c7c949 .xafd0a833 .xafd0a833-11 {
    color: rgba(100, 77, 157, 1);
    font-weight: 700;
    font-style: normal;
    font-size: 40.00px;
    font-family: NanumSquare_ac, Arial;
    letter-spacing: 0;
    text-decoration: none;
}

.mgames .contentbody .xc8c7c949 .x8eefb3db {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x8eefb3db.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 682.00px;
    height: 348.00px;
    left: 0.00px;
    top: 167.00px;

}

.mgames .contentbody .x93 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -340px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      stroke: rgba(194, 194, 194, 1);
          stroke-width: 1.00px;
          stroke-linecap: butt;
      box-shadow: none;
      width: 680.00px;
    height: 1.00px;
    left: 50%;
    top: 4570.00px;

}

.mgames .contentbody .x2178c405 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: auto;
    height: 1286.00px;
    left: 0.00px;
    top: 3137.00px;
    right: 0.00px;

}

.mgames .contentbody .x2178c405 .imagelistlatecia {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: auto;
      overflow-y: hidden;
      background-color: transparent;
      width: auto;
    height: 327.00px;
    left: 0.00px;
    top: 959.00px;
    right: 0.00px;

		-ms-overflow-style: none; /* IE scroll 숨김 */
}
.mgames .contentbody .x2178c405 .imagelistlatecia::-webkit-scrollbar {
	display:none; /* Chrome , Safari , Opera */
	width:0 !important;
		-ms-overflow-style: none; /* IE scroll 숨김 */
}
.mgames .contentbody .x2178c405 .imagelistlatecia::-webkit-scrollbar::-webkit-scrollbar {
	display:none; /* Chrome , Safari , Opera */
	width:0 !important;
		-ms-overflow-style: none; /* IE scroll 숨김 */
}.mgames .contentbody .x2178c405 .imagelistlatecia .x2 {
.mgames .contentbody .x2178c405 .imagelistlatecia::-webkit-scrollbar::-webkit-scrollbar::-webkit-scrollbar {
	display:none; /* Chrome , Safari , Opera */
	width:0 !important;
}
      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: auto;
      overflow-y: hidden;
      background-color: transparent;
      width: 3299.00px;
    height: 327.00px;
    left: 36.00px;
    top: 0.00px;

}

.mgames .contentbody .x2178c405 .imagelistlatecia .x2 .imageviewlatecia5 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/imageviewlatecia5.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 641.00px;
    height: 327.00px;
    left: 0.00px;
    top: 0.00px;

}

.mgames .contentbody .x2178c405 .imagelistlatecia .x2 .imageviewlatecia5e5e1db30 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/imageviewlatecia5e5e1db30.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 661.00px;
    top: 0.00px;
    right: 1997.00px;
    bottom: 0.00px;

}

.mgames .contentbody .x2178c405 .imagelistlatecia .x2 .imageviewlatecia596efd360 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/imageviewlatecia596efd360.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 1322.00px;
    top: 0.00px;
    right: 1336.00px;
    bottom: 0.00px;

}

.mgames .contentbody .x2178c405 .imagelistlatecia .x2 .imageviewlatecia5e4b75de9 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/imageviewlatecia5e4b75de9.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 1983.00px;
    top: 0.00px;
    right: 675.00px;
    bottom: 0.00px;

}

.mgames .contentbody .x2178c405 .imagelistlatecia .x2 .imageviewlatecia55dfdbeff {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/imageviewlatecia55dfdbeff.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 2644.00px;
    top: 0.00px;
    right: 14.00px;
    bottom: 0.00px;

}

.mgames .contentbody .x2178c405 .imagelistlatecia .imageviewlatecia {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: transparent;
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 750.00px;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    bottom: 0.00px;

}

.mgames .contentbody .x2178c405 .xc5641ccb {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -341px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 682.00px;
    height: 858.00px;
    left: 50%;
    top: 0.00px;

}

.mgames .contentbody .x2178c405 .xc5641ccb .latecialink {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 374.00px;
    height: 80.00px;
    left: 154.00px;
    top: 778.00px;

  border: none;
  outline: none;
}
.mgames .contentbody .x2178c405 .xc5641ccb .latecialink .x2255 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(100, 77, 157, 1);
      border-bottom-color: rgba(199, 199, 199, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(199, 199, 199, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(199, 199, 199, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(199, 199, 199, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 43.00px;
      border-top-right-radius: 43.00px;
      border-bottom-left-radius: 43.00px;
      border-bottom-right-radius: 43.00px;
      -webkit-filter: drop-shadow(0px 10px 6px rgba(158, 158, 158, 0.1607843137254902));
    filter: drop-shadow(0px 10px 6px rgba(158, 158, 158, 0.1607843137254902));
      width: 374.00px;
    height: 80.00px;
    left: 0.00px;
    top: 0.00px;

}

.mgames .contentbody .x2178c405 .xc5641ccb .latecialink .x5b1512b0 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 83.00px;
      letter-spacing: 0.00px;
      color: rgba(255, 255, 255, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 27.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 292.00px;
    height: 30.00px;
    left: 41.00px;
    top: -1.50px;

}




.mgames .contentbody .x2178c405 .xc5641ccb .x8c9a5013 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 76.00px;
      letter-spacing: 0.00px;
      color: rgba(38, 38, 38, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 38.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 131.00px;
    height: 42.00px;
    left: 21.00px;
    top: 430.00px;

}

.mgames .contentbody .x2178c405 .xc5641ccb .x800 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 46.00px;
      letter-spacing: -1.35px;
      color: rgba(55, 55, 55, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 27.00px;
      font-weight: 300;
    font-style: normal;
      font-family: 'Spoqa Han Sans Neo', Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      width: 666.00px;
    height: 129.00px;
    left: 8.00px;
    top: 525.00px;

}

.mgames .contentbody .x2178c405 .xc5641ccb .x17c00e6e {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x17c00e6e.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: auto;
    height: 404.00px;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;

}

.mgames .contentbody .x2178c405 .xc5641ccb .x17c00e6e .xb46cc0ca {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/xb46cc0ca.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -344.58819580078125px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 689.18px;
    height: 404.00px;
    left: 50%;
    top: 0.00px;

}

.mgames .contentbody .x2178c405 .xc5641ccb .x17c00e6e .x17637 {
    position: absolute;
      box-sizing: border-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: solid;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: solid;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: solid;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: solid;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}

.mgames .contentbody .x2178c405 .xc5641ccb .launchingIn2024 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 82.00px;
      letter-spacing: 0.00px;
      color: rgba(255, 96, 159, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 37.00px;
      font-weight: 700;
    font-style: normal;
      font-family: 'Gmarket Sans TTF', Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 432.00px;
    height: 37.00px;
    left: 246.00px;
    top: 426.50px;

}

.mgames .contentbody .x2178c405 .xc5641ccb .x9602e67b {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 30.00px;
      letter-spacing: -1.35px;
      color: rgba(122, 104, 245, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 27.00px;
      font-weight: 500;
    font-style: normal;
      font-family: 'Spoqa Han Sans Neo', Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      width: 492.00px;
    height: 37.00px;
    left: 12.00px;
    top: 707.00px;

}

.mgames .contentbody .lateciamovelinkposition {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 228.00px;
    height: 2.00px;
    left: 34.00px;
    top: 2994.00px;

  border: none;
  outline: none;
}
.mgames .contentbody .lateciamovelinkposition .lateciasharppos {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: transparent;
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}




.mgames .contentbody .xa89dba2a {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 100.00%;
    height: 1272.00px;
    left: 0.00px;
    top: 1579.00px;

}

.mgames .contentbody .xa89dba2a .imagelistmsl {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: auto;
      overflow-y: hidden;
      background-color: transparent;
      width: auto;
    height: 327.00px;
    left: 0.00px;
    top: 945.00px;
    right: 0.00px;

		-ms-overflow-style: none; /* IE scroll 숨김 */
}
.mgames .contentbody .xa89dba2a .imagelistmsl::-webkit-scrollbar {
	display:none; /* Chrome , Safari , Opera */
	width:0 !important;
		-ms-overflow-style: none; /* IE scroll 숨김 */
}
.mgames .contentbody .xa89dba2a .imagelistmsl::-webkit-scrollbar::-webkit-scrollbar {
	display:none; /* Chrome , Safari , Opera */
	width:0 !important;
		-ms-overflow-style: none; /* IE scroll 숨김 */
}.mgames .contentbody .xa89dba2a .imagelistmsl .x1 {
.mgames .contentbody .xa89dba2a .imagelistmsl::-webkit-scrollbar::-webkit-scrollbar::-webkit-scrollbar {
	display:none; /* Chrome , Safari , Opera */
	width:0 !important;
}
      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: auto;
      overflow-y: hidden;
      background-color: transparent;
      width: 3298.00px;
    height: 327.00px;
    left: 34.00px;
    top: 0.00px;

}

.mgames .contentbody .xa89dba2a .imagelistmsl .x1 .x2016 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x2016.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 641.00px;
    height: 327.00px;
    left: 0.00px;
    top: 0.00px;

}

.mgames .contentbody .xa89dba2a .imagelistmsl .x1 .x201614ed7547 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x201614ed7547.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 661.00px;
    top: 0.00px;
    right: 1996.00px;
    bottom: 0.00px;

}

.mgames .contentbody .xa89dba2a .imagelistmsl .x1 .x2016d63372b5 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x2016d63372b5.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 1322.00px;
    top: 0.00px;
    right: 1335.00px;
    bottom: 0.00px;

}

.mgames .contentbody .xa89dba2a .imagelistmsl .x1 .x201671cd6163 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x201671cd6163.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 1983.00px;
    top: 0.00px;
    right: 674.00px;
    bottom: 0.00px;

}

.mgames .contentbody .xa89dba2a .imagelistmsl .x1 .x201693bc6dc8 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x201693bc6dc8.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 2644.00px;
    top: 0.00px;
    right: 13.00px;
    bottom: 0.00px;

}

.mgames .contentbody .xa89dba2a .imagelistmsl .imageviewmsl {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: transparent;
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 750.00px;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    bottom: 0.00px;

}

.mgames .contentbody .xa89dba2a .x758c1066 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -341px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 682.00px;
    height: 846.00px;
    left: 50%;
    top: 0.00px;

}

.mgames .contentbody .xa89dba2a .x758c1066 .msl4 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/msl4.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 4.00px;
      border-top-right-radius: 4.00px;
      border-bottom-left-radius: 4.00px;
      border-bottom-right-radius: 4.00px;
      box-shadow: none;
      width: 682.00px;
    height: 404.00px;
    left: 0.00px;
    top: 0.00px;

}

.mgames .contentbody .xa89dba2a .x758c1066 .xd4fff3c3 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 76.00px;
      letter-spacing: 0.00px;
      color: rgba(100, 77, 157, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 38.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 229.00px;
    height: 42.00px;
    left: 21.00px;
    top: 429.00px;

}

.mgames .contentbody .xa89dba2a .x758c1066 .x800700Rpg {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 46.00px;
      letter-spacing: -1.35px;
      color: rgba(55, 55, 55, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 27.00px;
      font-weight: 300;
    font-style: normal;
      font-family: 'Spoqa Han Sans Neo', Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      width: 666.00px;
    height: 187.00px;
    left: 8.00px;
    top: 526.00px;

}

.mgames .contentbody .xa89dba2a .x758c1066 .facebookicon {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 48.50px;
    height: 48.50px;
    left: 452.16px;
    top: 445.50px;

  border: none;
  outline: none;
}
.mgames .contentbody .xa89dba2a .x758c1066 .facebookicon .facebookroundedsolidiconIconscom61562 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(100, 77, 157, 1);
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}




.mgames .contentbody .xa89dba2a .x758c1066 .instaicon {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 49.50px;
    height: 49.50px;
    left: 611.35px;
    top: 444.50px;

  border: none;
  outline: none;
}
.mgames .contentbody .xa89dba2a .x758c1066 .instaicon .x17601 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(100, 77, 157, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 3.84px;
    height: 3.95px;
    left: 29.60px;
    top: 15.69px;

}

.mgames .contentbody .xa89dba2a .x758c1066 .instaicon .x7056 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(100, 77, 157, 1);
      box-shadow: none;
      width: 19.98px;
    height: 12.74px;
    left: 14.76px;
    top: 22.02px;

}

.mgames .contentbody .xa89dba2a .x758c1066 .instaicon .x7057 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(100, 77, 157, 1);
      box-shadow: none;
      width: 7.58px;
    height: 6.45px;
    left: 20.96px;
    top: 22.04px;

}

.mgames .contentbody .xa89dba2a .x758c1066 .instaicon .x7058 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(100, 77, 157, 1);
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}




.mgames .contentbody .xa89dba2a .x758c1066 .youtubeicon {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 48.50px;
    height: 48.50px;
    left: 531.75px;
    top: 445.50px;

  border: none;
  outline: none;
}
.mgames .contentbody .xa89dba2a .x758c1066 .youtubeicon .x7059 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(100, 77, 157, 1);
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}

.mgames .contentbody .xa89dba2a .x758c1066 .youtubeicon .x7060 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(100, 77, 157, 1);
      box-shadow: none;
      width: 9.08px;
    height: 10.48px;
    left: 20.11px;
    top: 18.93px;

}




.mgames .contentbody .xa89dba2a .x758c1066 .x10932 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 624.00px;
    height: 83.00px;
    left: 29.00px;
    top: 763.00px;

}

.mgames .contentbody .xa89dba2a .x758c1066 .x10932 .appstorebuttonmsl {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 297.00px;
    height: 100.00%;
    top: 0.00px;
    right: 0.00px;

  border: none;
  outline: none;
}
.mgames .contentbody .xa89dba2a .x758c1066 .x10932 .appstorebuttonmsl .x17587 {
    position: absolute;
      box-sizing: border-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(0, 0, 0, 1);
      border-bottom-color: rgba(175, 175, 175, 1);
      border-bottom-style: solid;
      border-bottom-width: 3.00px;
      border-left-color: rgba(175, 175, 175, 1);
      border-left-style: solid;
      border-left-width: 3.00px;
      border-right-color: rgba(175, 175, 175, 1);
      border-right-style: solid;
      border-right-width: 3.00px;
      border-top-color: rgba(175, 175, 175, 1);
      border-top-style: solid;
      border-top-width: 3.00px;
      border-top-left-radius: 40.00px;
      border-top-right-radius: 40.00px;
      border-bottom-left-radius: 40.00px;
      border-bottom-right-radius: 40.00px;
      box-shadow: none;
      width: 297.00px;
    height: 83.00px;
    left: 0.00px;
    top: 0.00px;

}

.mgames .contentbody .xa89dba2a .x758c1066 .x10932 .appstorebuttonmsl .appStore {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 64.00px;
      letter-spacing: 0.00px;
      color: rgba(255, 255, 255, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 27.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 130.00px;
    height: 30.00px;
    left: 108.00px;
    top: 18.00px;

}

.mgames .contentbody .xa89dba2a .x758c1066 .x10932 .appstorebuttonmsl .downloadOnThe {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 63.00px;
      letter-spacing: 0.00px;
      color: rgba(210, 210, 210, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 16.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 122.00px;
    height: 18.00px;
    left: 108.00px;
    top: -9.50px;

}

.mgames .contentbody .xa89dba2a .x758c1066 .x10932 .appstorebuttonmsl .x26f114c8e {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x26f114c8e.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 59.00px;
    height: 60.00px;
    left: 47.00px;
    top: 9.00px;

}




.mgames .contentbody .xa89dba2a .x758c1066 .x10932 .googleplaybuttonmsl {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 299.00px;
    height: 100.00%;
    left: 0.00px;
    top: 0.00px;

  border: none;
  outline: none;
}
.mgames .contentbody .xa89dba2a .x758c1066 .x10932 .googleplaybuttonmsl .x17588 {
    position: absolute;
      box-sizing: border-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(0, 0, 0, 1);
      border-bottom-color: rgba(175, 175, 175, 1);
      border-bottom-style: solid;
      border-bottom-width: 3.00px;
      border-left-color: rgba(175, 175, 175, 1);
      border-left-style: solid;
      border-left-width: 3.00px;
      border-right-color: rgba(175, 175, 175, 1);
      border-right-style: solid;
      border-right-width: 3.00px;
      border-top-color: rgba(175, 175, 175, 1);
      border-top-style: solid;
      border-top-width: 3.00px;
      border-top-left-radius: 40.00px;
      border-top-right-radius: 40.00px;
      border-bottom-left-radius: 40.00px;
      border-bottom-right-radius: 40.00px;
      box-shadow: none;
      width: 299.00px;
    height: 83.00px;
    left: 0.00px;
    top: 0.00px;

}

.mgames .contentbody .xa89dba2a .x758c1066 .x10932 .googleplaybuttonmsl .googlePlay {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 63.00px;
      letter-spacing: 0.00px;
      color: rgba(255, 255, 255, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 25.00px;
      font-weight: 700;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 142.00px;
    height: 27.00px;
    left: 103.00px;
    top: 18.00px;

}

.mgames .contentbody .xa89dba2a .x758c1066 .x10932 .googleplaybuttonmsl .getItOn {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 64.00px;
      letter-spacing: 0.00px;
      color: rgba(210, 210, 210, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 15.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 67.00px;
    height: 17.00px;
    left: 103.00px;
    top: -9.50px;

}

.mgames .contentbody .xa89dba2a .x758c1066 .x10932 .googleplaybuttonmsl .x1e4c1b1b9 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x1e4c1b1b9.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 45.00px;
    height: 48.00px;
    left: 49.00px;
    top: 17.00px;

}




.mgames .contentbody .mslmovelinkposition {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 228.00px;
    height: 2.00px;
    left: 34.00px;
    top: 1484.00px;

  border: none;
  outline: none;
}
.mgames .contentbody .mslmovelinkposition .mslsharppos {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: transparent;
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}




.mgames .contentbody .x85eaf4c5 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 100.00%;
    height: 1440.00px;
    left: 0.00px;
    top: 0.00px;

}

.mgames .contentbody .x85eaf4c5 .x12 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/x12.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: auto;
    height: 1437.00px;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;

}

.mgames .contentbody .x85eaf4c5 .x12 .topbg {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/topbg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -375px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(0, 0, 0, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      -webkit-filter: blur(19.975200653076172px);
  filter: blur(19.975200653076172px);
      width: 750.00px;
    height: 1437.00px;
    left: 50%;
    top: 0.00px;

}

.mgames .contentbody .x85eaf4c5 .x12 .x01 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 0.3499999940395355;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(78, 78, 78, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 750.00px;
    height: 1437.00px;
    left: 0.00px;
    top: 0.00px;

}

.mgames .contentbody .x85eaf4c5 .videoelement {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 271.00px;
    height: 117.00px;
    left: 152.00px;
    top: 189.00px;

  border: none;
  outline: none;
}
.mgames .contentbody .x85eaf4c5 .videoelement .dummy {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: pointer;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: transparent;
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}




.mgames .contentbody .x85eaf4c5 .blackmask {
    position: absolute;
      box-sizing: border-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 0.25005999207496643;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(0, 0, 0, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: solid;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: solid;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: solid;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: solid;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}

.mgames .contentbody .x85eaf4c5 .x11010 {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: -315px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 630.00px;
    height: 120.00px;
    left: 50%;
    top: 660.00px;

}

.mgames .contentbody .x85eaf4c5 .x11010 .xe09bbef5 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      line-height: 68.00px;
      letter-spacing: 0.50px;
      color: rgba(255, 255, 255, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 50.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      -webkit-filter: drop-shadow(0px 3px 6px rgba(187, 187, 187, 0.1607843137254902));
    filter: drop-shadow(0px 3px 6px rgba(187, 187, 187, 0.1607843137254902));
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 174.00px;
    height: 56.00px;
    left: 228.00px;
    top: -6.00px;

}

.mgames .contentbody .x85eaf4c5 .x11010 .xbfd7fd67 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      line-height: 36.00px;
      letter-spacing: 0.00px;
      color: rgba(255, 255, 255, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 30.00px;
      font-weight: 400;
    font-style: normal;
      font-family: NanumSquare_ac, Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 630.00px;
    height: 34.00px;
    left: 0.00px;
    top: 85.00px;

}

.mgames .header {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: auto;
    height: 82.00px;
    left: 34.00px;
    top: 12.00px;
    right: 23.00px;

}

.mgames .header .logosmartstudygames2500x640f208d3ae {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      background-image: url("assets/logosmartstudygames2500x640f208d3ae.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: 309.00px;
    height: 79.00px;
    left: 0.00px;
    top: 0.00px;

}

.mgames .header .navibar {

      display: block;
      position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: unset;
      overflow-y: unset;
      background-color: transparent;
      width: 100.00px;
    height: 80.00px;
    right: 0.00px;
    bottom: 0.00px;

}

.mgames .header .navibar .x17579 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(67, 67, 67, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 5.00px;
      border-top-right-radius: 5.00px;
      border-bottom-left-radius: 5.00px;
      border-bottom-right-radius: 5.00px;
      box-shadow: none;
      width: 55.00px;
    height: 5.00px;
    right: 23.00px;
    bottom: 54.00px;

}

.mgames .header .navibar .x17580 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(67, 67, 67, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 5.00px;
      border-top-right-radius: 5.00px;
      border-bottom-left-radius: 5.00px;
      border-bottom-right-radius: 5.00px;
      box-shadow: none;
      width: 55.00px;
    height: 5.00px;
    right: 23.00px;
    bottom: 40.00px;

}

.mgames .header .navibar .x17594 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(67, 67, 67, 1);
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 5.00px;
      border-top-right-radius: 5.00px;
      border-bottom-left-radius: 5.00px;
      border-bottom-right-radius: 5.00px;
      box-shadow: none;
      width: 55.00px;
    height: 5.00px;
    right: 23.00px;
    bottom: 26.00px;

}

.mgames .header .navibar .x17638 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: transparent;
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 0.00px;
      border-top-right-radius: 0.00px;
      border-bottom-left-radius: 0.00px;
      border-bottom-right-radius: 0.00px;
      box-shadow: none;
      width: auto;
    height: auto;
    left: 0.00px;
    top: 0.00px;
    right: 0.00px;
    bottom: 0.00px;

}

